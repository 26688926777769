import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { date } from "yup";
import s from "../Dashboard.module.scss";
import moment from "moment";

export default function ActivityList() {

  const curDate = Date()
  const [orderdata, setOrderdata] = useState([]);
  useEffect(() => {
    const axiosHeader = {
      headers: { Accept: "*/*" }
    };
    fetch('https://api.x.immutable.com/v1/orders?buy_token_address=0xee972ad3b8ac062de2e4d5e6ea4a37e36c849a11&page_size=8', axiosHeader )
      .then((resp) => {
            if (resp.ok) {
              return resp.json()
            } 
            throw resp;
      })
      .then(data => {
        setOrderdata(data.result)
        // console.log("ORDER: " + JSON.stringify(orderdata))
      })
      .catch(error => {
        console.error("Error fetching ETH data: " + error)
      });

    // CLEAN UP as per https://stackoverflow.com/questions/54954385/react-useeffect-causing-cant-perform-a-react-state-update-on-an-unmounted-comp
    return () => {
      setOrderdata([])
    }
  }, [])

  return (
    < >
      {orderdata.map((order) =>
        <div key={uuidv4()} className={`mt-4 ${s.widgetBlock}`}>
          <div className={s.widgetBody}>
            <div className="d-flex">
              <img className="img-fluid mr-2" width="40" height="40" src={order.buy.data.properties.image_url} alt="..." />
              <div className="d-flex flex-column">
                <p className="body-2"> {order.buy.data.properties.name} 
                    <a href={"https://immutascan.io/address/" + order.buy.data.token_address + "/" + order.buy.data.token_id}  target="_blank" rel="noreferrer">
                    ( <i>Id:{order.buy.data.token_id} </i> )
                    </a>
                </p>
                {/* <p className="body-3 muted"> Status: { order.status } </p> */}
                <p className="body-3 muted">
                  {/* { order.timestamp.substring(0,19) }UTC  */}
                  { moment(order.timestamp).fromNow() }
                </p>
              </div>
            </div>
            <div className="body-2">
               {/* { (parseInt(order.sell.data.decimals))}  */}
               { new Intl.NumberFormat("en-US", { minimumFractionDigits: 3 }).format(order.sell.data.quantity/Math.pow(10, parseInt(order.sell.data.decimals)) ) } 
               {  order.sell.data.token_address === "0xccc8cb5229b0ac8069c51fd58367fd1e622afd97" ? "G" : 
                  order.sell.data.token_address === "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48" ? "$" :
                  order.sell.data.token_address === "0xf57e7e7c23978c3caec3c3548e3d615c346e79ff" ? "I" : 
                  order.sell.data.token_address === "0x9ab7bb7fdc60f4357ecfef43986818a2a3569c62" ? "Gg" :  "Ξ" }
            </div>
          </div>
        </div>
      )}
    </>
  )
}
