import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    position: "relative",
    zIndex: "10",
    justifyContent: "center",
    alignContent: "center",
    width: "80%",
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function TransitionAlert(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [gohome, setGohome] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setGohome(true);
  };

  useEffect(() => {
    setOpen(true);
    return () => {
      setOpen(false); // cleanup
    }
  }, [])


  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
             {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="secondary" autoFocus>
             Close
          </Button>
        </DialogActions>
      </Dialog>
      {gohome ? <Redirect to="/" /> : "" }

    </div>
  );
};
