
import React, {useState} from "react";
import classnames from "classnames";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import {
  // ComposedChart,
  ScatterChart,
  Scatter,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  // Treemap,
  // Bar,
  // Area,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
import highchartMore from "highcharts/highcharts-more";
import treemap from "highcharts/modules/treemap";
import heatmap from "highcharts/modules/heatmap";
import dumbbell from "highcharts/modules/dumbbell";
import sankey from "highcharts/modules/sankey";
import exporting from "highcharts/modules/exporting";
import eData from "highcharts/modules/export-data";
// import ApexCharts from "react-apexcharts";
import Widget from "components/Widget/Widget";
import s from "./OtherCharts.module.scss";
import chartsData from "./other/mock.js";

dumbbell(Highcharts);
highchartMore(Highcharts);
sankey(Highcharts);
exporting(Highcharts);
eData(Highcharts);
treemap(Highcharts);
heatmap(Highcharts);

export default function RaidCharts() {

  const [activeTab, setActiveTab] = useState(1);
  const { recharts } = chartsData;

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  return (
    <Widget className="charts-tabs-widget" style={{overflow: "auto"}}>
      <Nav tabs className="mb-5">
        <NavItem>
          <NavLink
            className={classnames({active: activeTab === 1})}
            onClick={() => toggleTab(1)}
          >
            <div className="headline-3">SAMPLE ONLY</div>
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({active: activeTab === 2})}
            onClick={() => toggleTab(2)}
          >
            <div className="headline-3">Recharts</div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({active: activeTab === 3})}
            onClick={() => toggleTab(3)}
          >
            <div className="headline-3">Highcharts</div>
          </NavLink>
        </NavItem> */}
      </Nav>
      <TabContent activeTab={activeTab} >
   
        <TabPane tabId={1}>
          <Col>
            <Row className="mb-5">
              <Col xs={12} lg={6}>
                <Widget>
                  <div className="headline-2 mb-4">Raid Chart - (Fake Data)</div>
                  <div className={s.rechartsBlock}>
                    <ResponsiveContainer width="100%" height="100%">
                      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={recharts.radarChart.data}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey="subject" />
                        <PolarRadiusAxis angle={30} domain={[0, 150]} />
                        <Radar name="John" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.5} />
                        <Radar name="Lily" dataKey="B" stroke="#FF5668" fill="#FF5668" fillOpacity={0.5} />
                        <Legend />
                      </RadarChart>
                    </ResponsiveContainer>
                  </div>
                </Widget>
              </Col>

              <Col xs={12} lg={6}>
                <Widget>
                  <div className="headline-2 mb-4">Scatter Chart - (Fake Data)</div>
                  <div className={s.rechartsBlock}>
                    <ResponsiveContainer width="100%" height="100%">
                      <ScatterChart
                        width={500}
                        height={400}
                        margin={{
                          top: 20,
                          right: 20,
                          bottom: 20,
                          left: 20,
                        }}
                      >
                        <CartesianGrid />
                        <XAxis type="number" dataKey="x" name="Attack" unit=" Atk" />
                        <YAxis 
                          yAxisId="left" 
                          type="number" 
                          dataKey="y" 
                          name="Health" 
                          unit=" HP" 
                          stroke="#8884d8" />
                        <YAxis
                          yAxisId="right"
                          type="number"
                          dataKey="y"
                          name="Mana"
                          unit=" Ma"
                          orientation="right"
                          stroke="#FF5668"
                        />
                        <Tooltip cursor={{ strokeDasharray: '6 3' }} />
                        <Scatter yAxisId="left" name="John" data={recharts.scatterChart.data1} fill="#8884d8" />
                        <Scatter yAxisId="right" name="Lily" data={recharts.scatterChart.data2} fill="#FF5668" />
                      </ScatterChart>
                    </ResponsiveContainer>
                  </div>
                </Widget>
              </Col>
              
            </Row>
      
          </Col>

        </TabPane>
      </TabContent>
    </Widget>
  )
}


