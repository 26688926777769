import React, { useEffect } from "react";
import "./Preloader.css";

function Preloader () {

  useEffect(() => {
    function preloader() {
      let preload = document.querySelector(".preloader");
      setTimeout(() => {
        preload.style.opacity = "0";
        setTimeout(() => {
          preload.style.display = "none";
        }, 1000);
      }, 1000);
    };
  preloader();

  }, [])

  return (
    <div className="preloader">
      <div className="spinner_wrap">
        <div className="spinner" />
      </div>
    </div>
  );

}

export default Preloader;
