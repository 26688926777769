import React, {useState, useEffect} from "react";
import classnames from "classnames";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from "reactstrap";
import {
  // Bar,
  // Area,
  // BarChart,
  CartesianGrid,
  // ReferenceLine,
  // PieChart,
  // Pie,
  Label,
  // Legend,
  Line,
  LineChart,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
import HighchartMore from "highcharts/highcharts-more";
import VariablePie from "highcharts/modules/variable-pie";
import Widget from "components/Widget/Widget";

import { jwAToken } from "config.js";
import s from "./PieCharts.module.scss";
import axios from "axios";

HighchartMore(Highcharts);
VariablePie(Highcharts);

export default function GoGMarketCharts() {

  const [activeTab, setActiveTab] = useState(1);
  const [isloading, setIsLoading] = useState(true)
  const [apidata, setApidata] = useState([]);
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  // /////////////////  DATA /////////////////
  // LOAD DATA
  useEffect(() => {
    const axiosHeader = {
      headers: { Authorization: `Bearer ${jwAToken}` }
    };
    const getData = async() => {
        const data = await axios.get('https://holistica.subzero.cloud:443/rest/gog_daily_trans', axiosHeader);
        // console.log(JSON.stringify(data.data[0].json_agg))
        setApidata(data.data[0].json_agg)
        setIsLoading(false)
      };

      getData();
  }, [])

  // /////////////////////////////////////////
  if (isloading) {
    console.log("APIDATA: " + JSON.stringify(apidata))
    return   <Spinner animation="grow" variant="primary" />
  } else {
    const recharts = {
      composedChart: {
        data: apidata,
      }
    }

    return (
      <Widget className="charts-tabs-widget" style={{overflow: "auto"}}>
        <Nav tabs className="mb-5">
          
          <NavItem>
            <NavLink
              className={classnames({active: activeTab === 1})}
              onClick={() => toggleTab(1)}
            >
              <div className="headline-3">Transactions by Day</div>
            </NavLink>
          </NavItem>

          {/* <NavItem>
            <NavLink
              className={classnames({active: activeTab === 2})}
              onClick={() => toggleTab(2)}
            >
              <div className="headline-3">Meta Charts</div>
            </NavLink>
          </NavItem> */}

        </Nav>
        {/*  END OF NAVIGATION  */}

        <TabContent activeTab={activeTab} >

        <TabPane tabId={1}>
          <Col>
            <Row className="mb-5">
              <Col xs={12} lg={10}>
                <Widget>
                  <div className="headline-2 mb-4">Daily Volume and USD Value</div>
                  <div className={s.rechartsBlock}>
                      <ResponsiveContainer width="100%" height="100%">
                      <LineChart
                        width={600}
                        height={400}
                        data={recharts.composedChart.data}
                        margin={{
                          top: 20,
                          right: 20,
                          left: 20,
                          bottom: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="dt" scale="band" />
                        <YAxis yAxisId="left" orientation="left" stroke="#76b5c5"> 
                          <Label
                              value="Value in USD"
                              // angle={-90}
                              position="insideTopLeft"
                              fill='#76b5c5'
                              fontSize={16}
                          />
                        </YAxis>
                        <YAxis yAxisId="right" orientation="right" stroke="#c58676">
                        <Label
                              value="Daily Transactions"
                              // angle={-90}
                              position="insideTopRight"
                              fill='#c58676'
                              fontSize={16}
                          />
                        </YAxis>
                        <Tooltip />
                        {/* <Legend /> */}
                        <Line yAxisId="left" dataKey="val" stroke="#76b5c5" dot="false" />
                        <Line yAxisId="right" dataKey="qty" stroke="#c58676" strokeWidth={3} />
                      </LineChart>
                    </ResponsiveContainer>

                  </div>
                </Widget>
              </Col>
            </Row>
          </Col>
          <Row>
            <div className="headline-3">USD Values are approximates.  Only one exchange rate is used per day.</div>
          </Row>
        </TabPane>
        </TabContent>
      </Widget>
    )
  }  //  END OF IF CLAUSE for RENDER: if (isloading)
}

