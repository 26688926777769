import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
// import config from "config";
import { connect } from "react-redux";
// import { push } from "connected-react-router";
// import jwt from "jsonwebtoken";
import { loginUser, receiveToken, doInit } from "actions/auth";

import {
  // Container,
  // Row,
  // Col,
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
// import Widget from "components/Widget/Widget";
// import Footer from "components/Footer/Footer";
// import GoogleIcon from "components/Icons/AuthIcons/GoogleIcon.js";
// import FacebookIcon from "components/Icons/AuthIcons/FacebookIcon.js";
// import { makeStyles } from "@material-ui/core/styles";
import Logo from "components/Icons/logo.png";
import loginImage from "assets/GoGHeroes_SideWallPaper.png";

const Login = (props) => {
  const [state, setState] = useState({
    email: 'aa@mail.com',
    password: 'Password01',
  })

  // const classes = useStyles();
 
  const doLogin = (e) => {
    e.preventDefault();
    props.dispatch(loginUser({ password: state.password, email: state.email }))
  }

  const changeCreds = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  useEffect(() => {
    const params = new URLSearchParams(props.location.search)
    const token = params.get('token');
    if (token) {
      props.dispatch(receiveToken(token))
      props.dispatch(doInit())
    }
  })
  
  return (
    <div
    style={{
      backgroundImage: "url(" + loginImage + ")",
      backgroundSize: "cover",
      backgroundPosition: "top center",
      width: '100vw',
      height: '100vh'
    }}
    >
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}} >
            <div className="widget-auth widget-p-lg" style={{ background: "white", }}>
              <div className="d-flex align-items-center justify-content-between py-3">
                <div className="logo-block">
                  <img src={Logo} alt="GUILD" />
                  <p className="mb-0">LOGIN</p> 
                  <p className="auth-header mb-0"></p>
                  &nbsp; <i>( Currently in BETA )</i>
                </div>
              </div>
              <div className="auth-info my-2">
                <p> Please enter your email and password to login!  </p>
              </div>
              <br />
              <div >
                <small>(aa@mail.com is the test user.  Register your email if you want to save your settings)</small>
              </div>
              <form onSubmit={(event) => doLogin(event)}>
                <FormGroup className="my-3">
                  <FormText>Email</FormText>
                  <Input
                    id="email"
                    className="input-transparent pl-3"
                    value={state.email}
                    onChange={(event) => changeCreds(event)}
                    type="email"
                    required
                    name="email"
                    placeholder="Email"
                  />
                </FormGroup>
                <FormGroup  className="my-3">
                  <div className="d-flex justify-content-between">
                    <FormText>Password</FormText>
                  </div>
                  <Input
                    id="password"
                    className="input-transparent pl-3"
                    value={state.password}
                    onChange={(event) => changeCreds(event)}
                    type="password"
                    required
                    name="password"
                    placeholder="Password"
                  />
                </FormGroup>
                <Link to="/forgot">Forgot password?</Link>

                <div className="bg-widget d-flex justify-content-center">
                  <Button className="rounded-pill my-3" type="submit" color="secondary-red">Login</Button>
                </div>
                <p className="dividing-line my-3">&#8195;Or&#8195;</p>
                
                {/* <div className="d-flex align-items-center my-3">
                  <p className="social-label mb-0">Login with</p>
                  <div className="socials">
                    <a href="https://flatlogic.com/"><GoogleIcon /></a>
                    <a href="https://flatlogic.com/"><TwitterIcon /></a>
                    <a href="https://flatlogic.com/"><FacebookIcon /></a>
                    <a href="https://flatlogic.com/"><GithubIcon /></a>
                    <a href="https://flatlogic.com/"><LinkedinIcon /></a>
                  </div>
                </div> */}

                <Link to="/register">Register / Create an account</Link>
                <br/>
       
              </form>
            </div>
      </div>
    </div>
  )

}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Login));
