import React, { useState } from "react";
// import jwt from "jsonwebtoken";
import {
  Col,
  Row,
  Button,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader,
  Badge,
  // ButtonDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem, Dropdown,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import Widget from "../../components/Widget/Widget";

import axios from "axios";
import { apiUrl, jwSToken} from "config.js";

import s from "./Profile.module.scss";
import TextField from '@material-ui/core/TextField';
// import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';

import Register from "components/Coming/Register";


export default function Profile() {
  const axiosHeader = {
    headers: {  Authorization: `Bearer ${jwSToken}`,
                'Content-Type': 'application/json' }
  };

  const currentUserEmail = sessionStorage.getItem('user_email')
  const [username, setUsername] = useState(sessionStorage.getItem('user_name'));
  const [metaaddress, setMetaaddress] = useState(sessionStorage.getItem('user_id'));
  const [userrole, setUserrole] = useState(sessionStorage.getItem('user_role'));
  // const usertoken = sessionStorage.getItem('token')
  ////////////////////////////////////////////

  // const decoded = jwt.verify(usertoken, 'UgbVncl31jfIN2XP0iFfGksIKuEBkcbDj1ORsCEDX7barR7XTh');
  // console.log("JWT: " + JSON.stringify(decoded)) // bar

  function handleSubmit(e) {
    e.preventDefault();
    const isFormValid = e.target.checkValidity()  
    if (isFormValid) {
      // const recaptchaValue = recaptchaRef.current.getValue();
      // this.props.handleSubmit(recaptchaValue);
      postSubmit();
    } else {
      // Form has errors, so don't create the account
      alert("Form has errors. Retry again. ");
      // return <TransitionAlert severity="error" message="Form has errors. Retry again." /> && setDone(true);
    }
  }; // END HANDLE SUBMIT

  function handleRole(e) {
    e.preventDefault();
    setUserrole(e.target.value);
  }
    
  function postSubmit() {
    axios.post(
      `${apiUrl}/rpc/paxedituser`, 
      {   "vemail": currentUserEmail,
          "vusername": username,
          "vuser_id": metaaddress.toLowerCase(),
          "vuser_role": userrole
      },
      axiosHeader
    ).then(result => {
      // alert( JSON.stringify(result) );
      if (result.status === 201 || result.status === 200) {
        alert("Your changes are effective immediately.");
        sessionStorage.setItem('user_name', username);
        sessionStorage.setItem('user_id', metaaddress.toLowerCase());
        sessionStorage.setItem('user_role', userrole);
      } else if (result.status === 401 ) {
        alert("Unauthorized Access");
      } else if (result.status === 409 ) {
        alert(" Error 409. Contact support@guild-tracker.com for issue.");
      } else {
        alert( JSON.stringify(result.message) );
      }
    })
    .catch(e => {
      alert("CATCH ERROR: Data RPC Post issue ");
      // alert(JSON.stringify(e) );                                            // REMOVE IN PRODUCTION
;
    })
    ;
  };

  const currentUser = sessionStorage.getItem("user_email");
  if (currentUser === "aa@mail.com") {
    return ( <Register/> )  
  }
  else
  {
    return(
      <div>
        <Form onSubmit={handleSubmit}>
        <Row className="mb-4">
          <Col lg='8' sm='12' className="mb-4 mb-lg-0">
            <Widget className={`widget-p-md ${s.profile}`}>
              <div className={s.profileTitle}>
                <div className="d-flex flex-column mb-2 ml-3">
                  {/* <p className="headline-1">Mary Sanders</p> */}
                  <p className="body-1 mt-1 mb-3"> {currentUserEmail} </p>
                  <Badge className="badge-inverse-primary"> Cannot change user email.  Please email support or create a new account. </Badge>
                  <hr />
                </div>
              </div>
              <div className="body-1 mb-4 base-light-gray">
                  <Typography variant="subtitle2" gutterBottom>
                    User Name
                  </Typography>
                  <TextField
                    id="username"
                    value={username}
                    multiline
                    // rowsMax={10}
                    fullWidth
                    margin="normal"
                    onChange={(e) => setUsername(e.target.value)}
                    helperText="User or display name. This is also used for searching"
                    size="small"
                    required
                  />

                  <Typography variant="subtitle2" gutterBottom>
                    User Address
                  </Typography>
                  <TextField
                    id="metaaddress"
                    value={metaaddress}
                    multiline
                    // rowsMax={1}
                    fullWidth
                    margin="normal"
                    onChange={(e) => setMetaaddress(e.target.value)}
                    helperText="Eth address used to hold your assets.  This does NOT connect to your wallet."
                    size="small"
                    required
                  />
              </div>
            </Widget>
          </Col>


          <Col lg='4' sm='12'>
            <Widget className="widget-p-md">
              <div className="headline-3 mb-1">Role</div>
              <div className="mb-4">
              <FormGroup check>
                  <Input
                    name="radio1"
                    type="radio"
                    value="Player"
                    onChange={handleRole}
                    checked={userrole === "Player"}
                  />
                  {' '}
                  <Label check>
                    Player. Default role. You have one address account.
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Input
                    name="radio1"
                    type="radio"
                    value="Manager"
                    onChange={handleRole}
                    checked={userrole==="Manager"}
                  />
                  {' '}
                  <Label check>
                    Manager.  You can have multiple address accounts
                  </Label>
                </FormGroup>         
              </div>


            </Widget>
          </Col>
        </Row>
        <Row>
          <Col lg={12} sm={12}>
            <Row className={s.widgetRow}>
              <Col xs={12} className="mb-4">
                <div>

                  <Button
                        color="danger"
                        onClick={handleSubmit}
                  >
                    Save Changes
                  </Button>
          
                </div>
              </Col>

            </Row>
          </Col>
        </Row>
        </Form>
      </div>
    )
    }
}
