import React, { useState, useEffect } from "react";
import classnames from "classnames";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  // Alert,
  FormText
} from "reactstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartMore from "highcharts/highcharts-more";

// import ApexCharts from "react-apexcharts";
import Widget from "components/Widget/Widget";
// import chartsData from "../charts/line/mock";
import s from "./LineCharts.module.scss";
// import usdeur from "./line/usdeur";
// import imxusdpricesf from "./line/ethusd";
// import ethusdpricesf from "./line/ethusd";

HighchartMore(Highcharts);

export default function TokenCharts() {

  const [activeTab, setActiveTab] = useState(1);
  // const {recharts } = chartsData;
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }
  
  // ////////////////////////////////////////////////////////////
  // DATA FETCH
  const [ethusdpricesf, setEthUsd] = useState([]);
  const [ethvolf, setEthVol] = useState([]);
  const [gogusdpricesf, setGogUsd] = useState([]);
  const [gogvolf, setGogVol] = useState([]);
  const [imxusdpricesf, setImxUsd] = useState([]);
  const [imxvolf, setImxVol] = useState([]);
  const [godsusdpricesf, setGodsUsd] = useState([]);
  const [godsvolf, setGodsVol] = useState([]);

  useEffect(() => {
    const axiosHeader = {
      headers: { Accept: "application/json" }
    };
    fetch('https://api.coingecko.com/api/v3/coins/ethereum/market_chart?vs_currency=usd&days=90&interval=daily', axiosHeader )
      .then((resp) => {
            if (resp.ok) {
              return resp.json()
            } 
            throw resp;
      })
      .then(data => {
        // console.log("FETCH: " + JSON.stringify(data.prices))        
        setEthUsd(data.prices);
        setEthVol(data.total_volumes);
      })
      .catch(error => {
        console.error("Error fetching ETH data: " + error)
      });
      
    fetch('https://api.coingecko.com/api/v3/coins/guild-of-guardians/market_chart?vs_currency=usd&days=90&interval=daily', axiosHeader )
      .then((resp) => {
            if (resp.ok) {
              return resp.json()
            } 
            throw resp;
      })
      .then(data => {
        // console.log("FETCH: " + JSON.stringify(data.prices))        
        setGogUsd(data.prices);
        setGogVol(data.total_volumes);
      })
      .catch(error => {
        console.error("Error fetching GOG data: " + error)
      });

    fetch('https://api.coingecko.com/api/v3/coins/immutable-x/market_chart?vs_currency=usd&days=90&interval=daily', axiosHeader )
      .then((resp) => {
            if (resp.ok) {
              return resp.json()
            } 
            throw resp;
      })
      .then(data => {
        // console.log("FETCH: " + JSON.stringify(data.prices))        
        setImxUsd(data.prices);
        setImxVol(data.total_volumes);
      })
      .catch(error => {
        console.error("Error fetching IMX data: " + error)
      });

      fetch('https://api.coingecko.com/api/v3/coins/gods-unchained/market_chart?vs_currency=usd&days=90&interval=daily', axiosHeader )
      .then((resp) => {
            if (resp.ok) {
              return resp.json()
            } 
            throw resp;
      })
      .then(data => {
        // console.log("FETCH: " + JSON.stringify(data.prices))        
        setGodsUsd(data.prices);
        setGodsVol(data.total_volumes);
      })
      .catch(error => {
        console.error("Error fetching GOG data: " + error)
      });

  }, [])
  // END OF FETCH //////////////////////////////////


  // ////////////////////////////////////////////////
  // START OF CHART FORMATS
  const highcharts = {
    /////////////////////////////////////////////
    // START OF timeseries ETH/USD
    ethtimeSeries: {
      credits: {
        enabled: false
      },
      colors: ['#1FA9DE'],
      chart: {
        type: 'area',
        zoomType: 'x'
      },
      title: {
        text: 'ETH to USD exchange rate over time'
      },
      xAxis: {
        type: 'datetime',
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'Exchange rate',
          crosshair: true
        },
        labels: {
          formatter: function () {
              return '$' + this.axis.defaultLabelFormatter.call(this);
          }
        }   
      },
      tooltip: {
        pointFormat: "<b><br>${point.y:,.0f}",
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 5
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      
      series: [{
        type: 'line',
        name: 'ETH to USD',
        data: ethusdpricesf,
      }]
    },
    // END OF timeseries ETHUSD

    // START of ETH marketcap
    ethmarketCap: {
      credits: {
        enabled: false
      },
      colors: ['#DE541F'],
      chart: {
        type: 'area',
        zoomType: 'x'
      },
      title: {
        text: 'ETH Market Volume in $USD over time'
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: 'Market Volume'
        },        
        labels: {
          formatter: function () {
              return '$' + this.axis.defaultLabelFormatter.call(this);
          }
        },   
        allowDecimals: false
      },
      tooltip: {
        pointFormat: '<b><br>${point.y:,.0f}',
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 1,
              y1: 0,
              x2: 0,
              y2: 2
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      
      series: [{
        type: 'column',
        name: '$ Vol',
        data: ethvolf,
      }]
    },
    // END OF ETH MARKET CAP
    // //////////////////////////////////////////////////////

    // ////////////////////////////////////////////
    // START OF timeseries GOG/USD
    ////////////////////////
    gogtimeSeries: {
      credits: {
        enabled: false
      },
      colors: ['#B34014'],
      chart: {
        type: 'area',
        zoomType: 'x'
      },
      title: {
        text: 'GOG to USD exchange rate over time'
      },
      xAxis: {
        type: 'datetime',
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'Exchange rate',
          crosshair: true
        },
        labels: {
          formatter: function () {
              return '$' + this.axis.defaultLabelFormatter.call(this);
          }
        }   
      },
      tooltip: {
        pointFormat: '<b><br>${point.y:,.3f}',
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 5
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      
      series: [{
        type: 'line',
        name: 'GOG to USD',
        data: gogusdpricesf,
      }]
    },
    // END OF timeseries GOGUSD
    // START of GOG marketcap
    gogmarketCap: {
      credits: {
        enabled: false
      },
      colors: ['#1487B3'],
      chart: {
        type: 'area',
        zoomType: 'x'
      },
      title: {
        text: 'GOG Market Volume in $USD over time'
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: 'Market Volume'
        },        
        labels: {
          formatter: function () {
              return '$' + this.axis.defaultLabelFormatter.call(this);
          }
        },   
        allowDecimals: true
      },
      tooltip: {
        pointFormat: '<b><br>${point.y:,.0f}',
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 1,
              y1: 0,
              x2: 0,
              y2: 2
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      
      series: [{
        type: 'column',
        name: '$ Vol',
        data: gogvolf,
      }]
    },
    // END OF timeseries GOGUSD
    // //////////////////////////////////////////////////////



    // ////////////////////////////////////////////
    // START OF timeseries GODS/USD
    ////////////////////////
    godstimeSeries: {
      credits: {
        enabled: false
      },
      colors: ['#B34014'],
      chart: {
        type: 'area',
        zoomType: 'x'
      },
      title: {
        text: 'GODS to USD exchange rate over time'
      },
      xAxis: {
        type: 'datetime',
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'Exchange rate',
          crosshair: true
        },
        labels: {
          formatter: function () {
              return '$' + this.axis.defaultLabelFormatter.call(this);
          }
        }   
      },
      tooltip: {
        pointFormat: '<b><br>${point.y:,.3f}',
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 5
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      
      series: [{
        type: 'line',
        name: 'GODS to USD',
        data: godsusdpricesf,
      }]
    },
    // END OF timeseries GODSUSD
    // START of GODS marketcap
    godsmarketCap: {
      credits: {
        enabled: false
      },
      colors: ['#1487B3'],
      chart: {
        type: 'area',
        zoomType: 'x'
      },
      title: {
        text: 'GODS Market Volume in $USD over time'
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: 'Market Volume'
        },        
        labels: {
          formatter: function () {
              return '$' + this.axis.defaultLabelFormatter.call(this);
          }
        },   
        allowDecimals: true
      },
      tooltip: {
        pointFormat: '<b><br>${point.y:,.0f}',
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 1,
              y1: 0,
              x2: 0,
              y2: 2
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      
      series: [{
        type: 'column',
        name: '$ Vol',
        data: godsvolf,
      }]
    },
    // END OF timeseries GODSUSD
    // //////////////////////////////////////////////////////

    // ////////////////////////////////////////////
    // START OF timeseries IMX/USD
    ////////////////////////
    imxtimeSeries: {
      credits: {
        enabled: false
      },
      colors: ['#D6450E'],
      chart: {
        type: 'area',
        zoomType: 'x'
      },
      title: {
        text: 'IMX to USD exchange rate over time'
      },
      xAxis: {
        type: 'datetime',
        crosshair: true
      },
      yAxis: {
        title: {
          text: 'Exchange rate',
          crosshair: true
        },
        labels: {
          formatter: function () {
              return '$' + this.axis.defaultLabelFormatter.call(this);
          }
        }   
      },
      tooltip: {
        pointFormat: '<b><br>${point.y:,.3f}',
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 5
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      
      series: [{
        type: 'line',
        name: 'IMX to USD',
        data: imxusdpricesf,
      }]
    },
    // END OF timeseries IMXUSD
    // START of IMX marketcap
    imxmarketCap: {
      credits: {
        enabled: false
      },
      colors: ['#0E9FD6'],
      chart: {
        type: 'area',
        zoomType: 'x'
      },
      title: {
        text: 'IMX Market Volume in $USD over time'
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: 'Market Volume'
        },        
        labels: {
          formatter: function () {
              return '$' + this.axis.defaultLabelFormatter.call(this);
          }
        },   
        allowDecimals: true
      },
      tooltip: {
        pointFormat: '<b><br>${point.y:,.0f}'
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 1,
              y1: 0,
              x2: 0,
              y2: 2
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          threshold: null
        }
      },
      
      series: [{
        type: 'column',
        name: '$ Vol',
        data: imxvolf,
      }]
    },
    // END OF timeseries IMXUSD
    // //////////////////////////////////////////////////////
  }

  return (
    <Widget className="charts-tabs-widget" style={{overflow: "auto"}}>
      <Nav tabs className="mb-5">
      
      {/* TAB HEADERS */}
        <NavItem>
          <NavLink
            className={classnames({active: activeTab === 1})}
            onClick={() => toggleTab(1)}
          >
            <div className="headline-3">GODS/USD</div>
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({active: activeTab === 2})}
            onClick={() => toggleTab(2)}
          >
            <div className="headline-3">GOG/USD</div>
          </NavLink>
        </NavItem>
        
        <NavItem>
          <NavLink
            className={classnames({active: activeTab === 3})}
            onClick={() => toggleTab(3)}
          >
            <div className="headline-3">ETH/USD</div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({active: activeTab === 4})}
            onClick={() => toggleTab(4)}
          >
            <div className="headline-3">IMX/USD</div>
          </NavLink>
        </NavItem>
      </Nav>

    <TabContent activeTab={activeTab} >
    {/* TAB CONTENTS */}
      <TabPane tabId={1}>
          <Col>
            <Row className="mb-4">
               <Col xs={12} lg={6}>
                <Widget>
                  <div className="headline-2 mb-4">GODS/USD Rates</div>
                  <div className={s.rechartsBlock}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={highcharts.godstimeSeries}
                    />
                  </div>
                </Widget>
              </Col>

              <Col xs={12} lg={6}>
              <Widget>
                  <div className="headline-2 mb-4">GODS Market Volume</div>
                  <div className={s.rechartsBlock}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={highcharts.godsmarketCap}
                    />
                  </div>
                </Widget>
              </Col>
             </Row>
          </Col>
          <FormText color="muted"> Drag mouse to choose a graph area to zoom in. </FormText>
          <hr/>
          <a href="https://www.coingecko.com/en/coins/gods-unchained" target="_blank" rel="noreferrer" >
             More exchange rate details here from Coingecko
          </a>
        </TabPane>


        {/* GOG/USD  */}
        <TabPane tabId={2}>
          <Col>
            <Row className="mb-4">
               <Col xs={12} lg={6}>
                <Widget>
                  <div className="headline-2 mb-4">GOG/USD Rates</div>
                  <div className={s.rechartsBlock}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={highcharts.gogtimeSeries}
                    />
                  </div>
                </Widget>
              </Col>

              <Col xs={12} lg={6}>
              <Widget>
                  <div className="headline-2 mb-4">GOG Market Volume</div>
                  <div className={s.rechartsBlock}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={highcharts.gogmarketCap}
                    />
                  </div>
                </Widget>
              </Col>
             </Row>
          </Col>
          <FormText color="muted"> Drag mouse to choose a graph area to zoom in. </FormText>
          <hr/>
          <a href="https://www.coingecko.com/en/coins/guild-of-guardians" target="_blank" rel="noreferrer" >
             More exchange rate details here from Coingecko
          </a>
        </TabPane>

        {/* ETH/USD  */}
        <TabPane tabId={3}>
          <Col>
            <Row className="mb-4">
               <Col xs={12} lg={6}>
                <Widget>
                  <div className="headline-2 mb-4">ETH/USD Rates</div>
                  <div className={s.rechartsBlock}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={highcharts.ethtimeSeries}
                    />
                  </div>
                </Widget>
              </Col>

              <Col xs={12} lg={6}>
              <Widget>
                  <div className="headline-2 mb-4">ETH Market Volume</div>
                  <div className={s.rechartsBlock}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={highcharts.ethmarketCap}
                    />
                  </div>
                </Widget>
              </Col>
             </Row>
          </Col>
          <FormText color="muted"> Drag mouse to choose a graph area to zoom in. </FormText>
          <hr/>
          <a href="https://www.coingecko.com/en/coins/ethereum" target="_blank" rel="noreferrer" >
             More exchange rate details here from Coingecko
          </a>
        </TabPane>
  

        {/* IMX/USD  */}
        <TabPane tabId={4}>
          <Col>
            <Row className="mb-4">
               <Col xs={12} lg={6}>
                <Widget>
                  <div className="headline-2 mb-4">IMX/USD Rates</div>
                  <div className={s.rechartsBlock}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={highcharts.imxtimeSeries}
                    />
                  </div>
                </Widget>
              </Col>

              <Col xs={12} lg={6}>
              <Widget>
                  <div className="headline-2 mb-4">IMX Market Volume</div>
                  <div className={s.rechartsBlock}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={highcharts.imxmarketCap}
                    />
                  </div>
                </Widget>
              </Col>
             </Row>
          </Col>
          <FormText color="muted"> Drag mouse to choose a graph area to zoom in. </FormText>
          <hr/>
          <a href="https://www.coingecko.com/en/coins/immutable-x" target="_blank" rel="noreferrer" >
             More exchange rate details here from Coingecko
          </a>
        </TabPane>
      </TabContent>
    </Widget>
  )
}
