import React from "react";
import ReactDOM from "react-dom";
import Preloader from "./components/Preloader/Preloader";
// import Timer from "./components/Countdown/Timer";
import Optin from "./components/Optin/RegOptin";

import "./styles.css";

export default function Soon() {
  return (
    <div className="Soon">
      <div className="container">
        <h1>
          Please Register
          <br />
          To enter your details
        </h1>
        {/* <Timer /> */}
        <Optin />
        <Preloader />
      </div>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Soon />, rootElement);