import React from 'react';

const FooterIcon = (props) => {
  return (
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="30.000000pt" height="30.000000pt" viewBox="0 0 1000.000000 1000.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,1000.000000) scale(0.100000,-0.100000)"
      fill="#000000" stroke="none">
      <path d="M4895 9122 c-49 -8 -102 -37 -161 -85 -35 -29 -847 -835 -1803 -1792
      -1918 -1918 -1791 -1782 -1791 -1913 0 -89 27 -138 139 -255 53 -55 858 -862
      1789 -1793 1285 -1284 1705 -1699 1740 -1716 54 -26 145 -33 199 -14 19 6 66
      36 103 66 38 30 854 840 1813 1800 1423 1423 1748 1754 1768 1795 32 69 32
      161 0 230 -19 41 -347 374 -1810 1838 -1439 1441 -1796 1792 -1836 1812 -49
      23 -109 35 -150 27z m151 -754 c21 -15 690 -679 1485 -1475 1261 -1264 1447
      -1453 1457 -1489 15 -52 15 -101 1 -145 -8 -25 -394 -418 -1478 -1502 -863
      -866 -1479 -1475 -1498 -1483 -42 -18 -137 -18 -177 1 -17 8 -691 675 -1498
      1482 -1353 1355 -1467 1471 -1478 1513 -14 51 -7 117 15 160 22 43 2921 2939
      2959 2956 20 9 57 13 102 12 58 -3 78 -8 110 -30z"/>
      <path d="M3752 6507 c-628 -628 -1142 -1149 -1142 -1157 0 -20 2280 -2300
      2300 -2300 20 0 2300 2281 2300 2300 0 19 -1276 1294 -1339 1339 -24 17 -50
      31 -57 31 -16 0 -84 -64 -659 -618 -475 -458 -615 -602 -615 -631 0 -22 439
      -472 461 -471 8 0 196 180 416 400 252 252 408 400 420 400 28 0 464 -429 463
      -455 0 -24 -1357 -1385 -1380 -1385 -23 0 -1380 1362 -1380 1384 0 12 341 361
      905 926 669 671 905 913 905 930 0 17 -61 84 -212 237 -117 117 -219 213 -228
      213 -8 0 -529 -514 -1158 -1143z"/>
      </g>
      </svg>
    
  );
}

export default FooterIcon;
