import React from "react";
import FooterIcon from "../Icons/FooterIcon.js";
import s from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={s.footer}>
      <span className={s.footerLabel}>2022 &copy; GUILD-TRACKER.COM or GUILD-TRACKER.ONLINE</span> &nbsp;
      <i> currently in BETA. </i>
      <FooterIcon />
    </div>
  )
}

export default Footer;
