const hostApi = process.env.NODE_ENV === "development"
  ? "https://holistica.subzero.cloud"
  : "https://holistica.subzero.cloud";

const portApi = process.env.NODE_ENV === "development"
  ? 443
  : 443;
const baseURLApi = `${hostApi}${portApi ? `:${portApi}` : ``}/rest`;

const redirectUrl = process.env.NODE_ENV === "development"
  ? "http://localhost:3000/"
  : "https://demo.flatlogic.com/sofia-react";


const exportdefault = {
  redirectUrl,
  hostApi,
  portApi,
  baseURLApi,
  remote: "https://sing-generator-node.herokuapp.com",
  isBackend: process.env.REACT_APP_BACKEND,
  auth: {
    email: 'admin2@test.com',
    password: 'password'
  },
};

export default exportdefault;

export const apiUrl = "https://holistica.subzero.cloud/rest";
export const jwAToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoidm1fYW5vbl9yb2xlIn0.pnQXPZQHNCty_rBqUcn-bix2HPf0-d2eclRo8RHQiy0";
export const jwBToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoidm1fYnV5ZXJfcm9sZSJ9.vNFumTmqVQtXCeZoKLNUr3V5ZZWs_msVhM00B3Pz4oc";
export const jwSToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoidm1fc2VsbGVyX3JvbGUifQ.LdJrulT1ZCT2VLjaaL1j_k8Q8O7VcVjPYVHVkkw2qlU";
export const supportEmail = "support@guild-tracker.com";
export const Version = "v.2022.02.07"