import React, {useState, useEffect} from "react";
import classnames from "classnames";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from "reactstrap";
import {
  // RadialBar,
  // RadialBarChart,
  // Legend,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
import ApexCharts from "react-apexcharts";
import HighchartMore from "highcharts/highcharts-more";
import VariablePie from "highcharts/modules/variable-pie";
import Widget from "components/Widget/Widget";

import { apiUrl, jwAToken } from "config.js";
import s from "./PieCharts.module.scss";
import axios from "axios";

HighchartMore(Highcharts);
VariablePie(Highcharts);

export default function FactionCharts() {

  const [activeTab, setActiveTab] = useState(1);
  const [isloading, setIsLoading] = useState(true)
  const [apidata, setApidata] = useState([]);
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  // /////////////////  DATA /////////////////
  // LOAD DATA
  useEffect(() => {
    const axiosHeader = {
      headers: { Authorization: `Bearer ${jwAToken}` }
    };
    const getData = async() => {
        const data = await axios.get(`${apiUrl}/gog_factioncharts_data`, axiosHeader);
        // console.log(JSON.stringify(data.data[0].json_agg))
        setApidata(data.data[0].json_agg)
        setIsLoading(false)
      };

      getData();
  }, [])

  // /////////////////////////////////////////
  if (isloading) {
    console.log("APIDATA: " + JSON.stringify(apidata[3]))
    return   <Spinner animation="grow" variant="primary" />
  } else {
    let rechartsdata = {
      twoLevelPie: {
        datainner: apidata[1].heroesdatainner ,
        // datainner: [
        //   { name: 'Group A', value: 400 },
        //   { name: 'Group B', value: 300 },
        //   { name: 'Group C', value: 300 },
        //   { name: 'Group D', value: 200 },
        // ],
        dataouter: apidata[0].heroesdataouter ,
        petsdataouter: apidata[5].petsdataouter,
        petsdatainner: apidata[6].petsdatainner,
        rarityouter: apidata[7].raritydataouter,
        rarityinner: apidata[8].raritydatainner,
      },
    } ; 

    const stackedBar = {
      series: [{
                name: 'Empire',
                // data: [44, 55, 41, 37, 22]
                data: apidata[2].Empire
              }, {
                name: 'Glade',
                data: apidata[3].Glade
              }, {
                name: 'Horde',
                data: apidata[4].Horde
              }],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        colors: ['#00A5FF', '#43BC13', '#FFC405'],
        xaxis: {
          categories: ["Dark", "Earth", "Fire", "Light", "Water"],
          labels: {
          //   formatter: function (val) {
          //     return val + "K"
          //   },
            style: {
              colors: "#6B859E",
            },
          }
        },
        yaxis: {
          title: {
            text: undefined,
            align: 'center',
          },
          labels: {
            style: {
              colors: "#6B859E",
            },
          },
        },
        // tooltip: {
        //   y: {
        //     formatter: function (val) {
        //       return val + "K"
        //     }
        //   }
        // },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
    };
    const COLORS = ['#00A5FF', '#43BC13', '#FFC405'];
    const RCOLORS = ['#5c7b32', '#327b60', '#7b3279'];
    const PCOLORS = ['#6868a2', '#535382', '#8686b5'];
    

    return (
      <Widget className="charts-tabs-widget" style={{overflow: "auto"}}>
        <Nav tabs className="mb-5">
          
          <NavItem>
            <NavLink
              className={classnames({active: activeTab === 1})}
              onClick={() => toggleTab(1)}
            >
              <div className="headline-3">Factions</div>
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({active: activeTab === 2})}
              onClick={() => toggleTab(2)}
            >
              <div className="headline-3">Meta</div>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} >

          <TabPane tabId={1}>
            <Col>
              <Row className="mb-4">
              <Col xs={12} lg={6}>
                  <Widget>
                    <div className="headline-2 mb-2">Heroes by Faction Distribution</div>
                    <div className={s.rechartsBlock}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie  data={rechartsdata.twoLevelPie.datainner} dataKey="value" cx="50%" cy="50%" 
                                innerRadius={60} outerRadius={110} fill="#70bcc0">
                              {rechartsdata.twoLevelPie.datainner.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                          </Pie>
                          <Pie  data={rechartsdata.twoLevelPie.dataouter} dataKey="value" cx="50%" cy="50%" 
                                innerRadius={120} outerRadius={140} fill="#8470c0" label />
              
                          <Tooltip cursor={{ stroke: 'red', strokeWidth: 2 }} />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </Widget>
                </Col>

                <Col xs={12} lg={6}>
                
                  <Widget>
                    <div className="headline-2 mb-2">Elements by Faction</div>
                    <ApexCharts
                      type="bar"
                      options={stackedBar.options}
                      series={stackedBar.series}
                    />
                  </Widget>
                </Col>
                
              </Row>
        
            </Col>
          </TabPane>

          {/* TAB 2 GROUP  */}
          <TabPane tabId={2}>
            <Col>
              <Row className="mb-4">
              <Col xs={12} lg={6}>
                  <Widget>
                    <div className="headline-2 mb-2">Heroes by Rarity Distribution</div>
                    <div className={s.rechartsBlock}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie  data={rechartsdata.twoLevelPie.rarityinner} dataKey="value" cx="50%" cy="50%" 
                                innerRadius={60} outerRadius={110} fill="#70bcc0">
                              {rechartsdata.twoLevelPie.rarityinner.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={RCOLORS[index % RCOLORS.length]} />
                              ))}
                          </Pie> 
                          <Pie  data={rechartsdata.twoLevelPie.rarityouter} dataKey="value" cx="50%" cy="50%" 
                                innerRadius={120} outerRadius={140} fill="#8470c0" label  />
                          <Tooltip cursor={{ stroke: 'red', strokeWidth: 2 }} />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </Widget>
                </Col>

                <Col xs={12} lg={6}>
                  <Widget>
                    <div className="headline-2 mb-2">Pets Distribution</div>
                    <div className={s.rechartsBlock}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie  data={rechartsdata.twoLevelPie.petsdatainner} dataKey="value" cx="50%" cy="50%" 
                                outerRadius={110} fill="#2F33A7">
                            {rechartsdata.twoLevelPie.petsdatainner.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={PCOLORS[index % PCOLORS.length]} />
                             ))}
                          </Pie>
                          <Pie  data={rechartsdata.twoLevelPie.petsdataouter} dataKey="value" cx="50%" cy="50%" 
                                innerRadius={120} outerRadius={140} fill="#a2a268" label="name" />
                          <Tooltip cursor={{ stroke: 'blue', strokeWidth: 5 }} />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </Widget>
                </Col>
              </Row>
        
            </Col>
          </TabPane>

        </TabContent>
      </Widget>
    )
  }  //  END OF IF CLAUSE for RENDER: if (isloading)
  /////////////////////////////////
}

