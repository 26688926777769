import React from "react";
import { Formik } from "formik";
import Loader from "components/Loader/Loader";
import InputFormItem from "components/FormItems/items/InputFormItem";
import Widget from "components/Widget/Widget";
import IniValues from "components/FormItems/iniValues";
import usersFields from "../usersFields";
import FormValidations from "components/FormItems/formValidations";
import { Alert } from 'reactstrap';
// import { jwAToken } from "config.js";

// import cx from 'classnames';
// import s from "../Users.module.scss";

const ChangePasswordForm = (props) => {

  const { saveLoading, isEditing, findLoading, record , isError} = props;
  // const [promoAlert, setPromoAlert] = useState(false); 

  const iniValues = () => {
    return IniValues(usersFields, props.record || {});
  }

  const formValidations = () => {
    return FormValidations(usersFields, props.record || {});
  }

  const handleSubmit = (values) => {
    const { ...data } = values || {};
    console.log("DATA: " + JSON.stringify(data))
    props.onSubmit(data);
  };

  // const title = () => {
  //   return "Change Password"
  // }
  // console.log(title)

  const passwordSchema = {
    currentPassword: { type: 'string', label: 'Current Password' },
    newPassword: { type: 'string', label: 'New Password' },
    confirmNewPassword: { type: 'string', label: 'Confirm new Password' },
  };

  const renderForm = () => {
    return (
      <>
      <Widget className="widget-p-md">
        <Formik
          onSubmit={handleSubmit}
          initialValues={iniValues()}
          validationSchema={formValidations()}
        >
          {(form) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <InputFormItem
                  name={'currentPassword'}
                  password
                  schema={passwordSchema}
                />

                <InputFormItem
                  name={'newPassword'}
                  schema={passwordSchema}
                  password
                />

                <InputFormItem
                  name={'confirmNewPassword'}
                  schema={passwordSchema}
                  password
                />
                <div>
                  <button
                    className="btn btn-primary mr-3"
                    disabled={saveLoading}
                    type="button"
                    onClick={form.handleSubmit}
                  >
                    {' '}
                    Change Password
                  </button>{' '}

                  <button
                    className="btn btn-secondary"
                    type="button"
                    disabled={saveLoading}
                    onClick={() => props.onCancel()}
                  >
                    {' '}
                    Cancel
                  </button>
                </div>
              </form>
            )
          }}
        </Formik>
      </Widget>
      </>
    );
  }

  if (findLoading) {
    return <Loader />;
  }

  if (isEditing && !record) {
    return <Loader />;
  }

  if (isError) { 
      <Alert
          color="primary"
          // className={cx(s.promoAlert, {[s.showAlert]: promoAlert})}
      >
        Password not changed.  Email <a href="mailto:support@guild-tracker.com" >support@guild-tracker.com</a> if issue persists!
      </Alert> 
  }

  return renderForm();

}

export default ChangePasswordForm;
