import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cn from "classnames";
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup";
import { changeActiveSidebarItem } from "../../actions/navigation.js";
import Logo from "../Icons/logo.png";
import "eva-icons/style/eva-icons.css";

const Sidebar = (props) => {

  const {
    activeItem = "",
    ...restProps
  } = props;

  console.log(restProps, activeItem);

  const [burgerBtnToggled, setBurgerBtnToggled] = useState(false);

  useEffect(() => {
    if (props.sidebarOpened) {
      setBurgerBtnToggled(true)
    } else {
      setTimeout(() => {
        setBurgerBtnToggled(false)
      }, 0)
    }
  },  [props.sidebarOpened])

  return (
    <nav className={cn(s.root, {[s.sidebarOpen]: burgerBtnToggled})}>
      <header className={s.logo}>
        <img src={Logo} alt="GUILD-TRACKER" />
        <span className={s.title}>GUILD TRACKER</span> 
      </header>
      <ul className={s.nav}>

        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Dashboard"
          isHeader
          iconName={<i className="eva eva-home-outline"/>}
          link="/template/dashboard"
          index="dashboard"
          // badge=" 🔝 "
        />
 
 
        <h5 className={[s.navTitle, s.groupTitle].join(" ")}> OPTIONS </h5>
        
        {/* START OF TABS */}

        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Charts"
          isHeader
          iconName={<i className="eva eva-pie-chart-outline"/>}
          link="/template/charts"
          index="charts"
          childrenLinks={[
            {
              header: 'Token Market', link: '/template/charts/tokens',
            },
            {
              header: 'GoG Market', link: '/template/charts/market',
            },
            {
              header: 'Faction/Meta Info', link: '/template/charts/faction',
            },
          ]}
        />
 
        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="User Assets"
          isHeader
          iconName={<i className="eva eva-person"/>}
          link="/template/userassets"
          index="userassets"
          childrenLinks={[
            {
              header: 'User Assets', link: '/template/userassets/assets',
            },
            {
              header: 'Charts', link: '/template/userassets/usercharts',
            },
            {
              header: 'Stats', link: '/template/userassets/userstats',
            },
          ]}
        />
 
        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Team Assets"
          isHeader
          iconName={<i className="eva eva-people"/>}
          link="/template/assets"
          index="assets"
          childrenLinks={[
            {
              header: 'All Assets', link: '/template/assets/allassets',
            },
            {
              header: 'Team Charts', link: '/template/assets/charts',
            },
            {
              header: 'Raid Planner', link: '/template/assets/raid',
            },
            {
              header: 'Team Stats', link: '/template/userassets/userstats',
            },
            {
              header: 'Tracker', link: '/template/userassets/userstats',
            },
          ]}
        />

        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Resources"
          isHeader
          iconName={<i className="eva eva-globe"/>}
          link="/template/resources"
          index="resources"
          childrenLinks={[
            {
              header: 'Resources/Links', link: '/template/resources',
            },
            {
              header: 'Support/Help', link: '/template/support',
            },
            ]}
        />

        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="User"
          isHeader
          iconName={<i className="eva eva-person-outline"/>}
          link="/admin"
          index="admin"
          exact={false}
          childrenLinks={[
            {
              header: 'My Profile', link: '/template/user/profile',
            },
            {
              header: 'Members Management', link: '/template/manage/users',
            },
            {
              header: 'Change Password', link: '/template/password'
            },
          ]}
        />

      </ul>
    </nav>
  );
}

Sidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem,
  };
};

export default withRouter(connect(mapStateToProps)(Sidebar));
