import React from "react";
import {
  Row,
  Col,
  // Button,
  // FormGroup,
  // Label,
} from 'reactstrap';
import Widget from "components/Widget/Widget";
// import GoogleIcon from "components/Icons/AuthIcons/GoogleIcon.js";
import FacebookIcon from "components/Icons/AuthIcons/FacebookIcon.js";
import TwitterIcon from "components/Icons/AuthIcons/TwitterIcon";
import MediumIcon from "components/Icons/AuthIcons/MediumIcon";
// import LinkedinIcon from "components/Icons/AuthIcons/LinkedinIcon";
import DiscordIcon from "components/Icons/AuthIcons/DiscordIcon";
import WebIcon from "assets/icons8-website-64.png";
import statsPie from "assets/dashboard/statsPie.svg";

import s from "pages/dashboard/Dashboard.module.scss";

export default function Resources() {

  return (
    <div>
      <Row>
        <Col>
          <Row className="gutter mb-4">
            <Col xs={12} lg={6}>
              <Widget className="widget-p-md">
                <div className="headline-2 mb-2"> Official Guild of Guardians Sites</div>
                <div className="d-flex align-items-center my-3">
                  <div className="socials">
                    <a href="https://discord.gg/UdejGr9Wg7" target="_blank" rel="noreferrer" ><DiscordIcon /></a>
                    <a href="https://twitter.com/GuildOfGuardian"  target="_blank" rel="noreferrer" ><TwitterIcon /></a>
                    <a href="https://www.facebook.com/guildofguardians" target="_blank" rel="noreferrer" ><FacebookIcon /></a>
                    <a href="https://guildofguardians.medium.com/" target="_blank" rel="noreferrer" ><MediumIcon /></a>
                    <a href="https://www.guildofguardians.com/" target="_blank" rel="noreferrer" ><img src={WebIcon} alt="GoG"/></a>
                  </div>
                </div>
                <br/><br/> 
                <div>
                  We are not affiliated with Guild of Guardians™.  We are a fan site dedicated to this game.
                </div>

                <br/><br/>
                <div>
                  <small>
                    ♦︎ The GoG market data are refreshed only twice an hour.  
                    <br/>
                    ♦︎  Conversions to USD are estimates.  Only one daily exchange rate is used for all transctions of that day.
                    <br/>
                    ♦︎ Additional GoG functionality will be made available once we get access to other GoG data.
                    <br/>
                    2022.03.15
                  </small>  
                </div>
                <br/>
              <Row>
                <a className={`btn-secondary-red ${s.statsBtn}`} href="mailto:support@guild-tracker.com?subject=Inquiry" role="button">
                      <img className={s.pieImg}  src={statsPie} alt="..." />
                      <div>
                        <p className="headline-2">Contact Us</p>
                        <p className="body-3">For comments, suggestions and issues.</p>
                      </div>
                </a>
              </Row>         
              </Widget>
            </Col>

            <Col xs={12} lg={6} className="mt-4 mt-md-0">
              <Widget className="widget-p-md">
                 <a class="twitter-timeline" data-height="700" href="https://twitter.com/GuildOfGuardian?ref_src=twsrc%5Etfw">Tweets by GuildOfGuardian</a>   
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
