import React from "react";
import { useHistory } from 'react-router-dom'
import "./Optin.css";


const Optin = (props) => {
  
  const hist =useHistory();

  const handleClick = () => {
    hist.push('/register')

  }
  
  return (
    <div className="optin">
      <p>You are using Test User--sign out.  Then register with your email to be able to see your own assets.</p>
      <br/>
      <button onClick={handleClick}>
         Back to Home
      </button>
    </div>
  );

}

export default Optin;
