import axios from "axios";
import config from "../config";
import https from "https";
import jwt from "jsonwebtoken";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
import Errors from "../components/FormItems/error/errors";
import { mockUser } from "./mock.js";
import { apiUrl, jwAToken } from "config.js";
// import Alert from "components/Alerts/Alert.js";
// import { JSONParser } from "@amcharts/amcharts4/core";

export const AUTH_FAILURE = 'AUTH_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const PASSWORD_RESET_EMAIL_REQUEST = 'PASSWORD_RESET_EMAIL_REQUEST';
export const PASSWORD_RESET_EMAIL_SUCCESS = 'PASSWORD_RESET_EMAIL_SUCCESS';
export const AUTH_INIT_SUCCESS = 'AUTH_INIT_SUCCESS';
export const AUTH_INIT_ERROR = 'AUTH_INIT_ERROR';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

const axiosHeader = {
  headers: { Authorization: `Bearer ${jwAToken}` },
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  }),
};

async function findMe() {
  if (config.isBackend) {
    const response = await axios.get('/auth/me');
    return response.data;
  } else {
    return mockUser;
  }
}

export function authError(payload) {
  return {
    type: AUTH_FAILURE,
    payload,
  };
}

export function doInit(token) {
  return async (dispatch) => {
    let currentUser = null;
    if (!config.isBackend) {
      currentUser = token;
      dispatch({
        type: AUTH_INIT_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } else {
      try {
        let token = sessionStorage.getItem('token');
        if (token) {
          currentUser = await findMe();
        }
        dispatch({
          type: AUTH_INIT_SUCCESS,
          payload: {
            currentUser,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: AUTH_INIT_ERROR,
          payload: error,
        });
      }
    }
  }
}

export function logoutUser() {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_REQUEST,
    });
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user_email');
    sessionStorage.removeItem('user_name');
    sessionStorage.removeItem('user_id');
    sessionStorage.removeItem('user_mgr');
    axios.defaults.headers.common['Authorization'] = "";
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    dispatch(push('/login'));
    // dispatch(push('/template/dashboard'));
  };
}

export function receiveToken(token) {
  return (dispatch) => {
    let user;

    if (config.isBackend) {
      user = jwt.decode(token)
    } else {
      user = {
        email: token.email,
        user: {
          id: token.initials,
        }
      }
    }
    // console.log(user);
    // console.log("receiveToken: " + JSON.stringify(token[0]));
    sessionStorage.setItem('token', token[0].token);
    sessionStorage.setItem('user_email', token[0].user_email);
    sessionStorage.setItem('user_name', token[0].user_name);
    sessionStorage.setItem('user_id', token[0].user_id);
    sessionStorage.setItem('user_mgr', token[0].user_mgr);
    sessionStorage.setItem('user_role', token[0].user_role);
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    dispatch({
      type: LOGIN_SUCCESS
    });
    dispatch(push('/app'));
  }
}

// ///////////////////////  LOGIN USER /////////////////////// //
export function loginUser(creds) {
  return (dispatch) => {

    // DEFAULT TO ACCEPT DUMMY CREDS.  Changed If clause to FALSE  because isBackend is undefined
    // if (!config.isBackend) {
    if (false) {
      dispatch(receiveToken('token'));
      dispatch(doInit());
      dispatch(push('/template'));
    } else {
      dispatch({
        type: LOGIN_REQUEST,
      });
      // SOCIAL or EMAIL login
      // console.log("creds.social: " + creds.social);
      // console.log("creds.email: " + creds.email);

      if (creds.social) {
        window.location.href = config.baseURLApi + "/auth/signin/" + creds.social + "?template=" + config.redirectUrl;
      } 
      else if (creds.email.length > 0 && creds.password.length > 0) {

            axios.post(
              `${apiUrl}/rpc/paxlogin`, 
              {
                "email": creds.email,
                "pass": creds.password
              },
              axiosHeader)
              .then(result => {
                  console.log("Result: " + JSON.stringify(result));
                  if (result.status === 200) {
                    const token = result.data;
                    // console.log("Token: " + JSON.stringify(token));
                    dispatch(receiveToken(token));
                    dispatch(doInit(token));
                    dispatch(push('/template'));
                  } else {
                    console.log("Login error: Email and/or password not accepted. (auth.js) ")
                    dispatch({
                      type: 'USERS_FORM_UPDATE_ERROR',
                    });
                  }
              })
              .catch(err => {
                console.log("Login error: Email and/or password not accepted. CATCH ERR: " + err)
                alert("Login error: Email and/or password not accepted. ")
                dispatch(authError(err.response.data));           
              })
          
          // const token = '[{"user_role":"Manager","user_email":"aa@mail.com","user_name":"Test User","user_id":"0x9D24ce40cF70B407F8d0Da13FB711404B4F4DEc7","user_mgr":"aa@mail.com","token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCIgOiAiYWFAbWFpbC5jb20iLCAibWdyIiA6ICJhYUBtYWlsLmNvbSIsICJleHAiIDogMTY1ODExNDUyMn0.U5uDkAJ_Aj3gDocJf6kiwri9eHyFvBW8Kes2wClxWrw"}],'
          // dispatch(receiveToken(token));
          // dispatch(doInit(token));
          // dispatch(push('/template'));
          
          // axios.post("/auth/signin/local", creds).then(res => {
          //   const token = res.data;
          //   dispatch(receiveToken(token));
          //   dispatch(doInit());
          //   dispatch(push('/template'));
          // }).catch(err => {
          //   dispatch(authError(err.response.data));
          // })

      } else {
        dispatch(authError('Something was wrong. Try again'));
        alert("Error with Login. ");
      }
    }
  };
}

export function verifyEmail(token) {
  return(dispatch) => {
    if (!config.isBackend) {
      dispatch(push('/login'));
    } else {
      axios.put('/auth/verify-email', {token}).then(verified => {
        if (verified) {
          toast.success("Your email was verified");
        }
      }).catch(err => {
        toast.error(err.response.data);
      }).finally(() => {
        dispatch(push('/login'));
      })
    }
  }
}

export function resetPassword(token, password) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(push('/login'));
    } else {
      dispatch({
        type: RESET_REQUEST,
      });
      axios.put('/auth/password-reset', { token, password }).then(res => {
        dispatch({
          type: RESET_SUCCESS,
        });
        toast.success('Password has been updated');
        dispatch(push('/login'));
      }).catch(err => {
        dispatch(authError(err.response.data));
      })
    }
  }
}

export function sendPasswordResetEmail(email) {
  return (dispatch) => {
    if (false) {
    // if (!config.isBackend) {
      dispatch(push('/login'));
    } else {
      dispatch({
        type: PASSWORD_RESET_EMAIL_REQUEST,
      });
      axios.get(`https://villager.pythonanywhere.com/api/v1/paxresetpass?user=${email}`)
      .then(res => {
        dispatch({
          type: PASSWORD_RESET_EMAIL_SUCCESS,
        });
        // toast.success('Email with resetting instructions has been sent. ');
        dispatch(push('/login'));
      }).catch(err => {
        dispatch(authError(err.response.data));
      })
    }
  }
}

export function registerUser(creds) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(push('/user/profile'));
    } else {
      dispatch({
        type: REGISTER_REQUEST,
      });

      if (creds.email.length > 0 && creds.password.length > 0) {
        axios.post('/auth/signup', creds).then(res => {
          dispatch({
            type: REGISTER_SUCCESS
          });
          toast.success("You've been registered successfully. Please check your email for verification link");
          dispatch(push('/user/profile'));
        }).catch(err => {
          dispatch(authError(err.response.data));
        })

      } else {
        dispatch(authError('Something was wrong. Try again'));
      }
    }
  };
}


