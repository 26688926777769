import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
import Footer from "components/Footer/Footer.js";
import hasToken from "services/authService";
import loginImage from "assets/registerImage.svg";
import Logo from "components/Icons/logo.png";
import {  sendPasswordResetEmail } from "actions/auth.js";
import axios from "axios";
// import { apiUrl, jwAToken } from "config.js";

const ForgotPasswordForm = (props) => {
  const [state, setState] = useState({ email: ''} )

  const changeCred = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const doForgotPasswordForm = (event) => {
    event.preventDefault();
    postUsers();
    props.dispatch(sendPasswordResetEmail({
      creds: state,
      history: props.history,
    }))
  }

  function postUsers() {
    axios.get(`https://villager.pythonanywhere.com/api/v1/paxresetpass?user=${state.email}`
    ).then(result => {
          // alert( JSON.stringify(result) );
          if (result.status === 201 || result.status === 200) {
            alert("You will receive an email to that contains your system generated password.");
            // dispatch(push('/login'));
          } else if (result.status === 401 ) {
            alert("Unauthorized Access");
          } else if (result.status === 409 ) {
            alert("Email already exists. Choose 'Forgot Password' to reset password or email support@guild-tracker.com for issue.");
          } else {
            alert("Email support@guild-tracker.com for issue." );
          }
        })
    .catch(e => {
      alert("CATCH ERROR: Data RPC Post issue.  Pls email support@guild-tracker.com ");
      // alert(JSON.stringify(e) );
    })
    ;
  };

  const { from } = props.location.state || { from: { pathname: '/template' } }

  if (!window.location.href.includes('extra')) {
    if (hasToken()) {
      return (
        <Redirect to={from} />
      );
    }
  }
  
  return (
    <div
    style={{
      backgroundImage: "url(" + loginImage + ")",
      backgroundSize: "cover",
      backgroundPosition: "top center",
      width: '100vw',
      height: '100vh'
    }}
    >

    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}} >
      <div className="widget-auth widget-p-lg" style={{ background: "white", }}>
          <div className="d-flex align-items-center justify-content-between py-3">
            <p className="auth-header mb-0">Forgot Password</p>
            <div className="logo-block">
              <img src={Logo} alt="GUILD" />
              {/* <p className="mb-0">GUILD-TRACKER</p> */}
            </div>
          </div>
          <div className="auth-info my-2">
            <p>Enter your email to receive a new system-generated password. </p>
          </div>
          <form onSubmit={(event) => doForgotPasswordForm(event)}>
            <FormGroup className="my-3">
              <FormText>Email</FormText>
              <Input  id="email" className="input-transparent pl-3" value={state.email} onChange={(event) => {changeCred(event)}} 
                      type="email" required name="email" placeholder=" " />
            </FormGroup>
        
            <div className="bg-widget d-flex justify-content-center">
              <Button className="rounded-pill my-3" type="submit" color="secondary-red">Submit</Button>
            </div>
            <p className="dividing-line my-3">&#8195; Or &#8195;</p>
      
             
            <Link to="/login">Login to account</Link>
          </form>
      </div>
      <Footer />
    </div>
  </div>
  )
}

ForgotPasswordForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(ForgotPasswordForm));
