import React from "react";
import { useHistory } from 'react-router-dom'
import "./Optin.css";


const Optin = (props) => {
  
  const hist =useHistory();

  const handleClick = () => {
    hist.push('/template/dashboard')

  }
  
  return (
    <div className="optin">
      <p>Want to be the first to know when this page is done?</p>
      <br/>
      <button onClick={handleClick}>
          <a href="mailto:support@guild-tracker.com?subject=Update Me" role="button"> CLICK </a>
      </button>
    </div>
  );

}

export default Optin;
