import React from 'react';

const MediumIcon = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="64px" height="64px"><circle cx="14" cy="24" r="12" fill="#2100c4"/><ellipse cx="34" cy="24" fill="#2100c4" rx="6" ry="11"/>
<ellipse cx="44" cy="24" fill="#2100c4" rx="2" ry="10"/>
</svg>
  );
}

export default MediumIcon;
