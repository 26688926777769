import config from "../config";
import jwt from "jsonwebtoken";
import { createContext, useContext } from 'react';

export const AuthContext = createContext();

const hasToken = () => {
  const token = sessionStorage.getItem('token');
  if (!config.isBackend && token) return true;
  if (!token) return false;
  const date = new Date().getTime() / 1000;
  const data = jwt.decode(token);
  if (!data) return;
  return date < data.exp;
}

export default hasToken;

export function useAuth() {
  return useContext(AuthContext);
};

//////////////////////////////////////////////////////////////////////////////////////////////////////
// SET UP Current user first.  Must be Partner and authenticated from /login or Seller Links should NOT render
export function currentUserRole() {
  const currentToken = JSON.parse(sessionStorage.getItem('tokens')) || '';
  if (currentToken) {
      return currentToken[0].role; 
  } else {
      return '';
  }
};

export function currentInitials() {
  const currentToken = JSON.parse(sessionStorage.getItem('tokens')) || '';
  if (currentToken) {
      return currentToken[0].initials.toUpperCase(); 
  } else {
      return '';
  }
};

export function currentUserEmail() {
  const currentToken = JSON.parse(sessionStorage.getItem('tokens')) || '';
  if (currentToken) {
      return currentToken[0].email; 
  } else {
      return '';
  }
};

export function currentUserFaves() {
  const currentToken = (sessionStorage.getItem('faves')) || '';
  if (currentToken) {
      return currentToken; 
  } else {
      return '';
  }
};