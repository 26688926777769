import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";

import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";

import Dashboard from "pages/dashboard/Dashboard";
import Profile from "pages/profile/Profile";
import UserViewPage from "components/Users/view/UsersViewPage";
import ChangePasswordFormPage from "components/Users/changePassword/ChangePasswordFormPage";
import UserFormPage from "components/Users/form/UserFormPage";
import Charts from "pages/extra/charts/Charts";
import Wizard from "pages/forms/wizard/Wizard";

import Login from "pages/auth/login/Login";
import Register from "pages/auth/register/Register";
import ForgotPassword from "pages/auth/forgot/ForgotPassword";

import AllAssets from "pages/Assets/AllAssets";
import UserAssets from "pages/Assets/UserAssets";
import AssetCharts from "pages/Assets/AssetCharts";
import UserAssetCharts from "pages/Assets/UserAssetCharts";
import TokensCharts from "pages/charts/TokenCharts";
import FactionCharts from "pages/charts/FactionCharts";
import MarketCharts from "pages/charts/GoGMarketCharts";
import RaidCharts from "pages/charts/RaidCharts";
import Coming from "components/Coming/Soon";
import ContactUs from "components/ContactUs/ContactUs";
import UserTable from "components/Table/UserTable";
import Resources from "pages/Others/Resources";

import s from "./Layout.module.scss";

const Layout = (props) => {
  return (
    <div className={s.root}>
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          <Breadcrumbs url={props.location.pathname} />
          <Switch>
 
            <Route path="/admin" exact render={() => <Redirect to="/admin/users" />} />
            <Route path="/admin/users/new" exact component={UserFormPage} />
            <Route path="/admin/users/:id/edit" exact component={UserFormPage} />
            <Route path="/admin/users/:id" exact component={UserViewPage} />

            <Route path="/template" exact render={() => <Redirect to="template/dashboard"/>} />
            <Route path="/template/dashboard" exact component={Dashboard}/>

            <Route path="/template/user" exact render={() => <Redirect to={"/template/user/profile"} />}/>
            <Route path="/template/manage/users" exact component={UserTable} />
            <Route path="/template/user/profile" exact component={Profile} />
            <Route path="/template/userassets" exact render={() => <Redirect to="userassets/assets"/>} />
            <Route path="/template/userassets/assets" exact component={UserAssets} />
            <Route path="/template/userassets/usercharts" exact component={UserAssetCharts} />
            <Route path="/template/userassets/userstats" exact component={Coming} />
            <Route path="/template/assets" exact render={() => <Redirect to="assets/allassets"/>} />
            <Route path="/template/assets/allassets" exact component={AllAssets} />
            <Route path="/template/assets/charts" exact component={AssetCharts} />
            <Route path="/template/assets/raid" exact component={RaidCharts} />
            <Route path="/template/charts" exact render={() => <Redirect to={"/template/charts/tokens"}/>} />
            <Route path="/template/charts/tokens" exact component={TokensCharts} />
            <Route path="/template/charts/faction" exact component={FactionCharts} />
            <Route path="/template/charts/market" exact component={MarketCharts} />
 
            <Route path="/template/password" exact component={ChangePasswordFormPage} />
            <Route path="/template/edit_profile" exact component={UserFormPage} />
            <Route path="/template/resources" exact component={Resources} />
            <Route path="/template/support" exact component={ContactUs} />
            
            <Route path="/template/forms/wizard" exact component={Wizard} />
            <Route path="/template/extra/charts" exact component={Charts} />
            <Route path="/template/extra/login" exact component={Login} />
            <Route path="/template/extra/register" exact component={Register} />
            <Route path="/forgot" exact component={ForgotPassword} />
            <Route path="/register" exact component={Register} />
            <Route path='*' exact render={() => <Redirect to="/error" />} />
          </Switch>
        </main>
        <Footer />
      </div>
    </div>
  );
}

Layout.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    currentUser: store.auth.currentUser,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
