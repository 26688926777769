import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
// import Widget from "../../../components/Widget/Widget.js";
import Footer from "../../../components/Footer/Footer.js";
import hasToken from "../../../services/authService";
// import loginImage from "../../../assets/registerImage.svg";
import Logo from "components/Icons/logo.png";
// import GoogleIcon from "../../../components/Icons/AuthIcons/GoogleIcon.js";
// import TwitterIcon from "../../../components/Icons/AuthIcons/TwitterIcon.js";
// import FacebookIcon from "../../../components/Icons/AuthIcons/FacebookIcon.js";
// import GithubIcon from "../../../components/Icons/AuthIcons/GithubIcon.js";
// import LinkedinIcon from "../../../components/Icons/AuthIcons/LinkedinIcon.js";
import { registerUser } from "actions/auth.js";
import axios from "axios";
import { apiUrl, jwAToken } from "config.js";
// import Terms from "components/Terms/TermsOfUse.js"

const Register = (props) => {
  const [state, setState] = useState({ email: '', password: ''} )

  const changeCred = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const doRegister = (event) => {
    event.preventDefault();
    postUsers();
    props.dispatch(registerUser({
      creds: state,
      history: props.history,
    }))
  }

  const axiosHeader = {
    headers: { Authorization: `Bearer ${jwAToken}` }
  };

  function postUsers() {
    axios.post(
      `${apiUrl}/rpc/paxsignup`, 
      {   "email": state.email,
          "pass": state.password
      },
      axiosHeader
    ).then(result => {
      // alert( JSON.stringify(result) );
      if (result.status === 201 || result.status === 200) {
        alert("You will receive an email to validate your account.");
        // dispatch(push('/user/profile'));
      } else if (result.status === 401 ) {
        alert("Unauthorized Access");
      } else if (result.status === 409 ) {
        alert("Email already exists. Choose 'Forgot Password' to reset password or email support@guild-tracker.com for issue.");
      } else {
        // alert( JSON.stringify(result.message) );
        alert("Last Else Error. Choose 'Forgot Password' to reset password or email support@guild-tracker.com for issue.");
      }
    })
    .catch(e => {
      alert("CATCH ERROR: Email already exists. Choose 'Forgot Password' to reset password or email support@guild-tracker.com for issue. ");
      // alert(JSON.stringify(e) );
    })
    ;
  };

  const { from } = props.location.state || { from: { pathname: '/template' } }

  if (!window.location.href.includes('extra')) {
    if (hasToken()) {
      return (
        <Redirect to={from} />
      );
    }
  }
  
  return (
    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}} >
      <div className="widget-auth widget-p-lg" style={{ background: "white", }}>
          <div className="d-flex align-items-center justify-content-between py-3">
            <p className="auth-header mb-0">Sign Up</p>
            <div className="logo-block">
              <img src={Logo} alt="GUILD.PH" />
              <p className="mb-0">GUILD-TRACKER</p>
            </div>
          </div>
          <div className="auth-info my-2">
            <p>Enter your email and password to create account. <i>Validation function to follow</i> </p>
          </div>
          <form onSubmit={(event) => doRegister(event)}>
            <FormGroup className="my-3">
              <FormText>Email</FormText>
              <Input  id="email" className="input-transparent pl-3" value={state.email} onChange={(event) => {changeCred(event)}} 
                      type="email" required name="email" placeholder="support@guild-tracker.com" />
            </FormGroup>
            <FormGroup  className="my-3">
              <div className="d-flex justify-content-between">
                <FormText>Password (min 8 chars, 1 cap letter and 1 number)</FormText>
              </div>
              <Input  id="password" className="input-transparent pl-3" 
                      value={state.password} 
                      onChange={(event) => changeCred(event)} 
                      type="password" 
                      required 
                      name="password"
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
                      // placeholder="At least 8 chars."
              />
              <div className="d-flex justify-content-between">
                <FormText>Confirm Password</FormText>
              </div>
              <Input  id="confirmPassword" className="input-transparent pl-3" 
                      pattern={`${state.password}`}
                      type="password"
                      required
                      name="confirmpassword"
              />
            </FormGroup>
            <div className="bg-widget d-flex justify-content-center">
              <Button className="rounded-pill my-3" type="submit" color="secondary-red">Sign Up</Button>
            </div>
            <p className="dividing-line my-3">&#8195; Or &#8195;</p>
      
            {/* <div className="d-flex align-items-center my-3">
              <p className="social-label mb-0">Create account with: </p>
              <div className="socials">
                <a href="https://flatlogic.com/" target = "_blank" rel = "noopener noreferrer">
                  <GoogleIcon />
                </a>
                <a href="https://twitter.com/flatlogic/" target = "_blank" rel = "noopener noreferrer">
                  <TwitterIcon />
                </a>
                <a href="https://www.facebook.com/flatlogic" target = "_blank" rel = "noopener noreferrer">
                  <FacebookIcon />
                </a>
                <a href="https://github.com/flatlogic/" target = "_blank" rel = "noopener noreferrer">
                  <GithubIcon />
                </a>
                <a href="https://www.linkedin.com/company/flatlogic/" target = "_blank" rel = "noopener noreferrer">
                  <LinkedinIcon />
                </a>
              </div>
            </div> */}
            
            <Link to="/login">Login to account</Link>
          </form>
      </div>
      <Footer />
    </div>
  )
}

Register.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Register));
