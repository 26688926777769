import React, {useEffect, useState} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { apiUrl, jwSToken } from "config.js";
import axios from 'axios';
import { Table } from "reactstrap";

const axiosHeader = {
    headers: { Authorization: `Bearer ${jwSToken}` }
  };

const INVENTORY_API_URL = `${apiUrl}/paxaccts`;

function App() {
    const [data, setData] = useState([]);
    const useremail = sessionStorage.getItem("user_email");
    const getData = async() => {
        const data = await axios.get(`${INVENTORY_API_URL}?mgraddress=eq.${useremail}`, axiosHeader);
        // console.log(JSON.stringify(data))
        setData(data.data)
    };

    useEffect(() => {
        getData();
    }, []);

    const [inEditMode, setInEditMode] = useState({
        status: false,
        rowKey: null
    });

    const [membername, setMembername] = useState(null);
    const [eaddress, setEaddress] = useState(null);
    const onEdit = ({id, currMembername, currEaddress}) => {
        setInEditMode({
            status: true,
            rowKey: id
        })
        setMembername(currMembername);
        setEaddress(currEaddress);
    }

    const updateInventory = ({id, newMemberName, newEAddress}) => {
        axios.post(`${apiUrl}/rpc/paxaccts_updt`, {
                vid: id,
                vmgraddress: useremail,
                vmember_name: newMemberName,
                veaddress: newEAddress
            },
            axiosHeader
            ).then(response => {
                // console.log(JSON.stringify(response))
                // reset inEditMode and unit price state values
                onCancel();
                // fetch the updated data
                getData();
        })
    }

    const onDelRow = ({id}) => {
        axios.post(`${apiUrl}/rpc/paxaccts_del`, 
            { vid: id }, 
            axiosHeader
            ).then(response => {
                // reset inEditMode and unit price state values
                console.log("RESPONSE: " + JSON.stringify(response));
                onCancel();
                // fetch the updated data
                getData();
            })
    }

    const onAddRow = () => {
      const newid = uuidv4();
      const newMemberName = "New Member";

      axios.post(`${apiUrl}/rpc/paxaccts_updt`, 
                    {   vid: newid, 
                        vmgraddress: useremail,
                        vmember_name: newMemberName,
                        veaddress: "" }, 
                    axiosHeader
                ).then(response => {
                    // reset inEditMode and unit price state values
                    console.log("RESPONSE: " + JSON.stringify(response));
                    onCancel();
                    // fetch the updated data
                    getData();
                })
    }

    const onSave = ({id, newMemberName, newEAddress}) => {
        updateInventory({id, newMemberName, newEAddress});
    }

    const onCancel = () => {
        // reset the inEditMode state value
        setInEditMode({
            status: false,
            rowKey: null
        })
        // reset the unit price state value
        setMembername(null);
        setEaddress(null);
    }

    return (
        <div>
            <h5>Team Members</h5>
            <Table striped bordered hover size="sm">
              <thead>
              <tr>
                  <th>Member Name</th>
                  <th>ETH Address</th>
                  <th>Commands</th>
              </tr>
              </thead>

              <tbody>
              {/* {console.log("DATA: " + JSON.stringify(data)) } */}
              { 
                data.map((item) => (
                <tr key={item.id}>
                    <td>
                      {
                      inEditMode.status && inEditMode.rowKey === item.id ? (
                          <input value={membername}
                                  onChange={(event) => setMembername(event.target.value)}
                          />
                      ) : (
                          item.member_name
                      )
                      }
                    </td>
                    <td>
                      {
                      inEditMode.status && inEditMode.rowKey === item.id ? (
                          <input value={eaddress}
                                  onChange={(event) => setEaddress(event.target.value)}
                          />
                      ) : (
                          item.eaddress
                      )
                      }
                    </td>
                    <td>
                        {
                        inEditMode.status && inEditMode.rowKey === item.id ? (
                            <React.Fragment>
                                <button
                                    className={"btn-success"}
                                    onClick={() => onSave({id: item.id, newMemberName: membername, newEAddress: eaddress})}
                                >
                                    Save
                                </button>

                                <button
                                    className={"btn-secondary"}
                                    style={{marginLeft: 8}}
                                    onClick={() => onCancel()}
                                >
                                    Cancel
                                </button>

                                <button
                                    className={"btn-warning"}
                                    style={{marginLeft: 8}}
                                    onClick={() => onDelRow({id: item.id})}
                                >
                                    Delete Row
                                </button>


                            </React.Fragment>
                          ) : (
                            <button
                                className={"btn-primary"}
                                onClick={() => onEdit({id: item.id, currMembername: item.member_name, currEaddress: item.eaddress})}
                            >
                                Edit
                            </button>
                          )
                        }
                    </td>
                </tr>
                ))
                }
                </tbody>
            </Table>
            <br/>
            <button
                className={"btn-primary"}
                onClick={() => onAddRow({id: uuidv4() })}
            >
                Add Row
            </button>
        </div>
    );
}


export default App;