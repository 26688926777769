import React, {useState, useEffect} from "react";
import classnames from "classnames";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from "reactstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartMore from "highcharts/highcharts-more";
import treemap from "highcharts/modules/treemap";
import heatmap from "highcharts/modules/heatmap";
import dumbbell from "highcharts/modules/dumbbell";
import sankey from "highcharts/modules/sankey";
import exporting from "highcharts/modules/exporting";
import eData from "highcharts/modules/export-data";
import ApexCharts from "react-apexcharts";
import Widget from "components/Widget/Widget";
import s from "./OtherCharts.module.scss";

import { apiUrl, jwAToken } from "config.js";
import axios from "axios";

dumbbell(Highcharts);
highchartMore(Highcharts);
sankey(Highcharts);
exporting(Highcharts);
eData(Highcharts);
treemap(Highcharts);
heatmap(Highcharts);

export default function OtherCharts() {

  const [isloading1, setIsLoading1] = useState(true);
  const [isloading2, setIsLoading2] = useState(true);
  const [heatdata, setHeatdata] = useState([]);
  const [packdata, setPackdata] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const usrmgr = sessionStorage.getItem('user_mgr');
  // const { highcharts } = chartsData;

  // /////////////////  DATA /////////////////
  // LOAD DATA
  useEffect(() => {
      const axiosHeader = {
        headers: { Authorization: `Bearer ${jwAToken}` }
      };
      const getData = async() => {
        const data = await axios.get(`${apiUrl}/gog_assetheatmap_bymgr_data?user_id=eq.${usrmgr}`, axiosHeader);
        // console.log(JSON.stringify(data.data[0].json_agg))
        setHeatdata(data.data[0].json_agg)
        setIsLoading1(false)
      };
      getData();

      const getData2 = async() => {
        const data2 = await axios.get(`${apiUrl}/gog_assetbubble_bymgr_data?user_id=eq.${usrmgr}`, axiosHeader);
        console.log(JSON.stringify(data2.data[0].jagg))
        setPackdata(data2.data[0].jagg)
        setIsLoading2(false)
      };
      getData2();
  }, [])

  if (isloading1 && isloading2) {
    // console.log("APIDATA: " + JSON.stringify(packdata))
    return   <Spinner animation="grow" variant="primary" />
  } else { 

      const toggleTab = (tab) => {
        if (activeTab !== tab) {
          setActiveTab(tab);
        }
      }
      
      const heatmapChart = {
        series: heatdata,
        options: {
          chart: {
            height: 350,
            type: 'heatmap',
            toolbar: {
              show: false
            },
          },
          dataLabels: {
            enabled: false
          },
          title: {
            text: undefined,
            align: 'center'
          },
          colors: ['#FFA100'],
          xaxis: {
            categories: ['Dark', 'Earth', 'Fire', 'Light', 'Water', 'Other']
          }
        },
      }
      
      const packedBubble = {
        chart: {
          type: 'packedbubble',
          height: '80%'
        },
        title: {
          text: 'Heroes by Faction'
        },
        colors: ['#00A5FF', '#43BC13', '#FFC405'],
        tooltip: {
          useHTML: true,
          pointFormat: '<b>{point.name}:</b> {point.value} '
        },
        plotOptions: {
          packedbubble: {
            minSize: '30%',
            maxSize: '100%',
            zMin: 0,
            zMax: 1000,
            layoutAlgorithm: {
              gravitationalConstant: 0.15,
              splitSeries: true,
              seriesInteraction: false,
              dragBetweenSeries: true,
              parentNodeLimit: true
            },
            dataLabels: {
              enabled: true,
              format: '{point.name}',
              filter: {
                property: 'y',
                operator: '>',
                value: 1
              },
              style: {
                color: 'black',
                textOutline: 'none',
                fontWeight: 'normal',
              }
            }
          }
        },
        series: packdata
      }

      return (
        <Widget className="charts-tabs-widget" style={{overflow: "auto"}}>
          <Nav tabs className="mb-5">
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === 1})}
                onClick={() => toggleTab(1)}
              >
                <div className="headline-3">Team Heroes Charts</div>
              </NavLink>
            </NavItem>
        
          </Nav>

          <TabContent activeTab={activeTab} >
            <TabPane tabId={1}>
              <Col>
                <Row className="mb-4">
                  <Col xs={12} lg={6}>
                    <Widget>
                      <div className="headline-2 mb-2">Class by Element Distribution</div>
                      <ApexCharts
                        type="heatmap"
                        series={heatmapChart.series}
                        options={heatmapChart.options}
                      />
                    </Widget>
                  </Col>

                  <Col xs={12} lg={6}>
                    <Widget>
                      <div className="headline-2 mb-4">Asset Distribution</div>
                      <div className={s.rechartsBlock}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={packedBubble}
                        />
                      </div>
                    </Widget>
                  </Col>

                </Row>
      
              </Col>
            </TabPane>
    
          </TabContent>
        </Widget>
      )
  } 
  // END if (isloading1 && isloading2)
  ///////////////////////
}


