import React, { useState, useEffect } from "react";
// import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  // Progress,
  // Button,
  // ButtonDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import ActivityList from "./components/ActivityList.js";
import PetsList from "./components/PetsList.js";
import SumOrder from "./components/SumOrders.js"

import heartRed from "../../assets/dashboard/heartRed.svg";
import heartTeal from "../../assets/dashboard/heartTeal.svg";

import statsPie from "../../assets/dashboard/statsPie.svg";

import s from "./Dashboard.module.scss";

export default function Dashboard() {
  // const [checkboxes, setCheckboxes] = useState([true, false])

  // const toggleCheckbox = (id) => {
  //   setCheckboxes(checkboxes => checkboxes
  //     .map((checkbox, index) => index === id ? !checkbox : checkbox ))
  // }

  const [ethdata, setEthdata] = useState([]);
  const [gogdata, setGogdata] = useState([]);
  const [imxdata, setImxdata] = useState([]);
  const [bitdata, setBitdata] = useState([]);

  useEffect(() => {
    const axiosHeader = {
      headers: { Accept: "application/json" }
    };
    fetch('https://api.coingecko.com/api/v3/simple/price?ids=ethereum%2Cgods-unchained%2Cimmutable-x%2Cguild-of-guardians&vs_currencies=usd&include_market_cap=true&include_24hr_change=true&include_last_updated_at=true', axiosHeader )
      .then((resp) => {
            if (resp.ok) {
              return resp.json()
            } 
            throw resp;
      })
      .then(data => {
        // console.log("FETCH: " + JSON.stringify(data))
        setEthdata(data.ethereum);
        setBitdata(data['gods-unchained']);
        setGogdata(data['guild-of-guardians']);
        setImxdata(data['immutable-x']);
        // console.log("ETH: " + JSON.stringify(gogdata))
      })
      .catch(error => {
        console.error("Error fetching ETH data: " + error)
      });
      // CLEAN UP as per https://stackoverflow.com/questions/54954385/react-useeffect-causing-cant-perform-a-react-state-update-on-an-unmounted-comp
      return () => {
        setEthdata([]);
        setBitdata([]);
        setGogdata([]);
        setImxdata([]);
      }
  }, [])

  return (
    <div>
      {/* TILES ROW */}
      <Row className="gutter">

      <Col className="mb-4 mb-xl-0" xs={12} sm={6} xl={3}>
          <Widget className="widget-p-sm">
            <div className={s.smallWidget}>
              <div className="d-flex mb-4">
                <img className="py-1 mr-2 img-fluid" src={bitdata.usd_24h_change <0 ? heartRed : heartTeal} alt="..." />
                <div className="d-flex flex-column">
                  <p className="headline-3"> GODS/USD </p>
                  <p className="body-2"> $ {bitdata.usd} <span className="body-3 muted"> Chg. { 
                            new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(bitdata.usd_24h_change/100) } </span></p>

                </div>
              </div>
            </div>
          </Widget>
        </Col>

        <Col className="mb-4 mb-xl-0" xs={12} sm={6} xl={3}>
          <Widget className="widget-p-sm">
            <div className={s.smallWidget}>
              <div className="d-flex mb-4">
                <img className="py-1 mr-2 img-fluid" src={gogdata.usd_24h_change < 0 ? heartRed : heartTeal} alt="..." />
                <div className="d-flex flex-column">
                  <p className="headline-3">GOG/USD</p>
                  <p className="body-2"> $ {gogdata.usd} <span className="body-3 muted"> Chg. { 
                            new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(gogdata.usd_24h_change/100) } </span></p>
                </div>
              </div>
              <div>
                <p className="body-3"> 
                  <span className="body-3 muted"> 
                    {/* Last Update: { new Intl.DateTimeFormat("en-US", { dateStyle: 'short', timeStyle: 'medium'}).format(gogdata.last_update_at) }  */}
                  </span>
                </p>
              </div>
            </div>
          </Widget>
        </Col>
        <Col className="mb-4 mb-xl-0" xs={12} sm={6} xl={3}>
          <Widget className="widget-p-sm">
            <div className={s.smallWidget}>
              <div className="d-flex mb-4">
                <img className="py-1 mr-2 img-fluid" src={imxdata.usd_24h_change <0 ? heartRed : heartTeal}  alt="..." />
                <div className="d-flex flex-column">
                  <p className="headline-3">IMX/USD</p>
                  <p className="body-2"> $ {imxdata.usd} <span className="body-3 muted"> Chg. { 
                            new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(imxdata.usd_24h_change/100) } </span></p>
         
                </div>
              </div>
             </div>
          </Widget>
        </Col>
        <Col xs={12} sm={6} xl={3}>
          <Widget className="widget-p-sm">
            <div className={s.smallWidget}>
              <div className="d-flex mb-4">
                <img className="py-1 mr-2 img-fluid" src={ethdata.usd_24h_change <0 ? heartRed : heartTeal} alt="..." />
                <div className="d-flex flex-column">
                  <p className="headline-3">ETH/USD</p>
                  <p className="body-2"> $ {ethdata.usd} <span className="body-3 muted"> Chg. { 
                            new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2}).format(ethdata.usd_24h_change/100) } </span></p>

                </div>
              </div>
            </div>
          </Widget>
        </Col>
      </Row>

      <br/>
      <div>
        <p className="body-3"> 
          <span className="body-3 muted"> 
            Last Update of above tiles in local time: { new Intl.DateTimeFormat("en-US", { dateStyle: 'short', timeStyle: 'medium'}).format(imxdata.last_update_at) } 
            &nbsp; Change is day on day
          </span>
        </p>
      </div>

      <br/>

      {/* CHARTS ROW */}
      <Row>
          {/*  3rd COLUMN */}
          <Col className="mb-4 mb-md-0" md={8} lg={4}>
            <Widget className="widget-p-md">
              <SumOrder />  
            </Widget>

          </Col>
                      
          {/* 1ST COLUMN */}
          <Col className="mb-4 mb-md-0" md={12} lg={4}>
            <Widget className="widget-p-md">
              <div className="d-flex justify-content-between ">
                <div className="headline-3 d-flex align-items-center">Latest Heroes Activity</div>
              </div>
              <ActivityList/>
            </Widget>
          </Col>

          {/* 2ND COLUMN */}
          <Col className="mb-4 mb-md-0" md={12} lg={4}>
            <Widget className="widget-p-md">
              <div className="d-flex justify-content-between">
                <div className="headline-3 d-flex align-items-center">Latest Pets Activity</div>
              </div>
              <PetsList/>
            </Widget>
          </Col>

      
      </Row>

      <Row>
        <a className={`btn-secondary-red ${s.statsBtn}`} href="mailto:support@guild-tracker.com?subject=Inquiry" role="button">
              <img className={s.pieImg}  src={statsPie} alt="..." />
              <div>
                <p className="headline-2">Contact Us</p>
                <p className="body-3">For comments, suggestions and issues.</p>
              </div>
        </a>
      </Row>
    </div>
  )
}
