import {
  Form
} from "reactstrap";
import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { apiUrl, jwAToken } from "../../config.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";

// @material-ui/core/core components
import { styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import "./AllAssets.css";
import { CardActions } from "@material-ui/core";

const axiosHeader = {
headers: { Authorization: `Bearer ${jwAToken}` }
};

const ExpandMore = styled((props) => {
const { expand, ...other } = props;
return <IconButton {...other} />;
})(({ theme, expand }) => ({
transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
marginLeft: 'auto',
transition: theme.transitions.create('transform', {
  duration: theme.transitions.duration.shortest,
}),
}));

export default function AllAssets() {

const [expanded, setExpanded] = React.useState(false);
const handleExpandClick = () => {
  setExpanded(!expanded);
};

// /////////////////////////////////////
// data fetching from the API
const [assetfeed, handleassetfeed] = useState([]);
const [filters, setFilters] = useState('');
const usrid = sessionStorage.getItem('user_email');

useEffect(() => {
  FetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

const FetchData = async() => {
  const data = await axios.get(
    `${apiUrl}/gog_assets_byuser?user_id=eq.${usrid}`,
    axiosHeader
  );
  // const assetfeed = await data.json();
  const assetfeed = data.data[0].json_agg;
  // alert(JSON.stringify(assetfeed));
  handleassetfeed(assetfeed);
}
// END fetch //////////////////

// ////////////////////////////////
// //// Filter Returned Result Set 
const handleSubmit = () => {
    let filteredfeed = assetfeed.filter( asset =>  
          { 
            return asset.asset_name.toLowerCase().includes(filters.toLowerCase()) ||
            asset.meta_class?.toLowerCase().includes(filters.toLowerCase()) ||
            asset.meta_rarity?.toLowerCase().includes(filters.toLowerCase()) ||
            asset.asset_element?.toLowerCase().includes(filters.toLowerCase()) ||
            asset.asset_faction?.toLowerCase().includes(filters.toLowerCase()) ||
            asset.user_name?.toLowerCase().includes(filters.toLowerCase()) 
          }
        );
    handleassetfeed(filteredfeed);
    console.log("Filters: " + filters)
    // console.log("ASSETFEED: " + JSON.stringify(assetfeed))
}

////////////////////////////////

const StoreArea = () => (
    <Fragment>
           {assetfeed.map((tile) => (
            <GridItem sm={6} md={3} lg={2} xl={2} >
              <Card>
              <CardMedia
              // className={}
              style={{height:100, paddingTop: '35%'}}
              image={tile.image_url}
              />
                  <CardContent>
                      {/* <CardActionArea onClick={() => openLink(siteUrl + tile.subsite_link)} > */}
                      <Typography color="textSecondary" gutterBottom>
                          {tile.asset_name}
                      </Typography>
                      {/* </CardActionArea> */}

                      <Typography variant="caption" component="p">
                          {!tile.meta_serialnum ? "" : "Serial #: " + tile.meta_serialnum}
                      </Typography>

                      <Typography variant="body2" color="textSecondary" gutterBottom component="p">
                          {!tile.meta_class ? "" : "Class: " + tile.meta_class}
                      </Typography>                   

                  </CardContent>
        
                  <CardActions disableSpacing>
                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </CardActions>

                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent className={tile.asset_faction === 'Empire' ? 'background-empire' : tile.asset_faction === 'Glade' ? 'background-glade' : 
                                              tile.asset_faction === 'Horde' ? 'background-horde' : 'background-none'}>
                      <Typography variant="caption"  component="p">
                          { !tile.asset_faction ? "" : "Faction: " + tile.asset_faction}
                      </Typography>

                      <Typography variant="caption"  component="p">
                          { !tile.asset_element ? "" : "Element: "+ tile.asset_element}
                      </Typography>
                      
                      <Typography variant="caption" color="textSecondary" component="p">
                          Rarity: {tile.meta_rarity}
                      </Typography>

                      <Typography variant="caption" color="textSecondary" component="p">
                          Owner: {tile.user_name}
                      </Typography>

                    </CardContent>
                  </Collapse>  
            
              </Card>
            </GridItem>
          ))}
  </Fragment>
);


return (
  <div>
    <div className={'in-rows'}>
      <Form onSubmit={handleSubmit} >
        <span >
          <input
            onChange={(e) => setFilters(e.target.value)}
            type="search"
            id="searchInput"
            style={{ marginLeft: "10px", marginRight: "10px"}}
            autoFocus
            placeholder="Enter to search. "
          />
          &nbsp;  &nbsp;
          <Button variant="contained" size="small" onClick={FetchData} > Reset / Reload </Button>
        </span>
      </Form>
    </div>
    <br/>
    <GridContainer spacing={3}>
        <GridItem xs={12} sm={12} md={12} >
          <GridContainer spacing={3}>
            <StoreArea/>
          </GridContainer>
        </GridItem>  
      </GridContainer>
  </div>
);
}
