
import React, {useState} from "react";
import { Redirect } from "react-router-dom";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

// core components
import TransitionAlert from "components/Alerts/TransitionAlert.js";
import axios from "axios";
import { apiUrl, jwAToken } from "config.js";


export default function ContactUs() {
  const [name, setName] = useState(" ");
  const [email, setEmail] = useState(" ");
  const [msg, setMsg] = useState(" ");
  const [nameFocus, setNameFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDone, setDone] = useState(false);

  React.useEffect(() => {
    document.body.classList.add("contact-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  function onFormSubmit(e) {
    e.preventDefault();
    postMsg() 
  }
  
  // API CALL
  const axiosHeader = {
    headers: { Authorization: `Bearer ${jwAToken}` }
  };
  function postMsg() {
    axios.post(
      `${apiUrl}/rpc/vmcontactus`, 
      {   "vemail": email,
          "vname": name,
          "vphone": '',
          "vmsg": msg
      },
      axiosHeader
    ).then(result => {
      // alert( JSON.stringify(result) );
      if (result.status === 201 || result.status === 200) {
        setIsError(false);
        setDone(true);
        alert("Message has been sent.");
      } else if (result.status === 401 ) {
        setIsError(false);
        setDone(true);
        alert("Unauthorized Access");
      } else if (result.status === 409 ) {
        setIsError(false);
        setDone(true);
        alert(" Error 409. Email support@guild-tracker.com for issue.");
      } else {
        setIsError(true);
        // alert( JSON.stringify(result.message) );
      }
    })
    .catch(e => {
      alert("CATCH ERROR: Data RPC Post issue ");
      // alert(JSON.stringify(e) );
      setIsError(true);
    })
    ;
  }

  if (isDone) {
    return <Redirect to="/template/dashboard"/>;
  };

  if (isError) {
    return <TransitionAlert severity="error" message="There was an error. Email support@guild-tracker.com" />
  };

  return (
    <>
      <Row>
        <Col className="ml-auto mr-auto" md="5">
            <h2 className="title">Send us a message</h2>
            <p className="description">
            You can contact us with anything related to our site.
            We'll get in touch with you as soon as possible. <br></br>
            <br></br>
            </p>
            <Form id="contact-form" onSubmit={onFormSubmit} role="form">
            <label>Your name</label>
            <InputGroup
                className={nameFocus ? "input-group-focus" : ""}
            >
                <InputGroupAddon addonType="prepend">
                <InputGroupText>
                    <i className="now-ui-icons users_circle-08"></i>
                </InputGroupText>
                </InputGroupAddon>
                <Input
                aria-label="Your Name..."
                autoComplete="name"
                placeholder="Your Name..."
                type="text"
                onFocus={() => setNameFocus(true)}
                onBlur={() => setNameFocus(false)}
                onChange={(e) => setName(e.target.value)}
                ></Input>
            </InputGroup>
            <label>Email address</label>
            <InputGroup
                className={emailFocus ? "input-group-focus" : ""}
            >
                <InputGroupAddon addonType="prepend">
                <InputGroupText>
                    <i className="now-ui-icons ui-1_email-85"></i>
                </InputGroupText>
                </InputGroupAddon>
                <Input
                aria-label="Email Here..."
                autoComplete="email"
                placeholder="Email Here..."
                type="email"
                onFocus={() => setEmailFocus(true)}
                onBlur={() => setEmailFocus(false)}
                onChange={(e) => setEmail(e.target.value)}
                ></Input>
            </InputGroup>

            <FormGroup>
                <label>Your message</label>
                <Input
                id="message"
                name="message"
                rows="6"
                type="textarea"
                onChange={(e) => setMsg(e.target.value)}
                ></Input>
            </FormGroup>
            <div className="submit text-center">
                <Button
                className="btn-raised btn-round"
                color="info"
                defaultValue="Contact Us"
                type="submit"
                >
                    Submit
                </Button>
            </div>
            </Form>
        </Col>
      </Row>
    </>
  );
}
;