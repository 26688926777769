import React, {useState, useEffect} from "react";
// import classnames from "classnames";
import {
  // Row,
  // Col,
  // TabContent,
  // TabPane,
  // Nav,
  // NavItem,
  // NavLink,
  Spinner,
} from "reactstrap";

import ApexCharts from "react-apexcharts";
import Highcharts from "highcharts";
import HighchartMore from "highcharts/highcharts-more";
import VariablePie from "highcharts/modules/variable-pie";
import Widget from "components/Widget/Widget";
import axios from "axios";
import { apiUrl, jwAToken } from "config.js";

// import s from "./LineCharts.module.scss";

HighchartMore(Highcharts);
VariablePie(Highcharts);

export default function SumOrders() {
 
  // ////////////////
  // LOAD DATA
  const [isloading, setIsLoading] = useState(true)
  const [apidata, setApidata] = useState([]);

  useEffect(() => {
    const axiosHeader = {
      headers: { Authorization: `Bearer ${jwAToken}` }
    };
    const getData = async() => {
      const data = await axios.get(`${apiUrl}/gog_db_asset_rpt`, axiosHeader);
      // console.log(JSON.stringify(data.data[0].json_agg))
      setApidata(data.data[0].json_agg)
      setIsLoading(false)
    };
    getData();
    // CLEAN UP as per https://stackoverflow.com/questions/54954385/react-useeffect-causing-cant-perform-a-react-state-update-on-an-unmounted-comp
    return () => {
      setApidata([]);
    }
  }, [])
 

  if (isloading) {
    // console.log("APIDATA: " + JSON.stringify(apidata))
    return   <Spinner animation="grow" variant="primary" />
  } else {
      const dataout = apidata
      // console.log("FETCH: " + JSON.stringify(dataout))
      const apexCharts = {
        lineColumn: {
          series24H: [{
            name: 'Volume',
            type: 'column',
            data: [dataout["H24Avatars"]["f1"] || 0,  dataout["H24Heroes"]["f1"] , dataout["H24Pets"]["f1"] , dataout["H24Tokens"]["f1"]]
            // data: [10,5,6,7]
          }, {
            name: 'USD',
            type: 'column',
            data: [dataout["H24Avatars"]["f2"],  dataout["H24Heroes"]["f2"] , dataout["H24Pets"]["f2"] , dataout["H24Tokens"]["f2"]],
            // data: [10,5,6,7]

          }],
          series7D: [{
            name: 'Volume',
            type: 'column',
            data: [dataout["D07Avatars"]["f1"],  dataout["D07Heroes"]["f1"] , dataout["D07Pets"]["f1"] , dataout["D07Tokens"]["f1"]]
          }, {
            name: 'USD',
            type: 'column',
            data: [dataout["D07Avatars"]["f2"],  dataout["D07Heroes"]["f2"] , dataout["D07Pets"]["f2"] , dataout["D07Tokens"]["f2"]]
          }],
          series30D: [{
            name: 'Volume',
            type: 'column',
            data: [dataout["D30Avatars"]["f1"],  dataout["D30Heroes"]["f1"] , dataout["D30Pets"]["f1"] , dataout["D30Tokens"]["f1"]]
          }, {
            name: 'USD',
            type: 'column',
            data: [dataout["D30Avatars"]["f2"],  dataout["D30Heroes"]["f2"] , dataout["D30Pets"]["f2"] , dataout["D30Tokens"]["f2"]]
          }],
          options: {
            colors: ["#C7D0D9", "#7560b9"],
            chart: {
              height: 350,
              type: 'line',
              toolbar: {
                show: false,
              },
            },
            stroke: {
              curve: "straight",
              width: [0, 1]
            },
            dataLabels: {
              enabled: true,
              enabledOnSeries: [1],
              style: {
                fontSize: '10px',
                fontWeight: 500,
              },
              background: {
                borderWidth: 0,
              },
            },
            labels: ["Avatars", "Heroes", "Pets", "Tokens"],
            xaxis: {
              type: 'category',
              labels: {
                style: {
                  colors: "#6B859E",
                },
              },
            },
            yaxis: [
              {
                title: {
                  text: 'Volume',
                  style: {
                    fontSize: '12px',
                    fontWeight: 400,
                    color: "#6B859E",
                  },
                },
                labels: {
                  style: {
                    colors: ["#6B859E"],
                  },
                },
              }, {
                opposite: true,
                title: {
                  text: 'USD',
                  style: {
                    fontSize: '12px',
                    fontWeight: 400,
                    color: "#6B859E",
                  },
                },
                labels: {
                  style: {
                    colors: ["#6B859E"],
                  },
                },
              }],
            fill: {
              type: "solid",
              opacity: 1,
            }
          },
        },
      }
      // //////////////  END DATA SETTING

      return (
          <>
            <Widget>
              <div className="headline-3 d-flex align-items-center">Past 24 Hours</div>
              <ApexCharts
                series={apexCharts.lineColumn.series24H}
                options={apexCharts.lineColumn.options}
                type="area"
              />
            </Widget>

            <Widget>
              <div className="headline-3 d-flex align-items-center">Past 7 Days</div>
              <ApexCharts
                series={apexCharts.lineColumn.series7D}
                options={apexCharts.lineColumn.options}
                type="area"
              />
            </Widget>
            
            <Widget>
                <div className="headline-3 d-flex align-items-center">Past 30 Days</div>
                <ApexCharts
                  series={apexCharts.lineColumn.series30D}
                  options={apexCharts.lineColumn.options}
                  type="area"
                />
            </Widget>
          </>
      )
    } // /////////////////////
    //  END OF IF CLAUSE for RENDER: if (isloading)
}

